<template>
  <transition name="toggleContentComponent">
    <article class="fullscreenIframePlayer fullscreenVideo" v-if="ready">
      <!-- TOPBAR -->
      <nav class="fullscreenVideo__topbar">
          <button class="button__close btnfx btnfx__hoverscale" @click="closeComponent">
              <img src="@/assets/img/ui/close_circle.png" />
          </button>
          <h3 v-html="getComponentTitle"></h3>
      </nav>

      <div class="fullscreenVideo__center">
        <article v-if="!isIPhone">
          <iframe ref="youtube"
            if="properties.embedURL !== ''"
            :src="embedURL"
            frameborder="0"
            allowfullscreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen" 
            msallowfullscreen="msallowfullscreen" 
            oallowfullscreen="oallowfullscreen" 
            webkitallowfullscreen="webkitallowfullscreen"></iframe>
        </article>
        <article class="guard" v-else>
          <p v-html="properties.guardText"></p>
          <button @click="openContent(properties.guardContentID)" class="Hotspot defaultHotspot btnfx defaultHotspot--fullscreenVideo">
            <div class="defaultHotspot__button btnfx__clicklight btnfx__hoverhalo"></div>
            <div class="defaultHotspot__label show">
              <span v-html="properties.guardCaption"></span>
            </div>
          </button>
        </article>
      </div>

      <div class="fullscreenVideo__background">
          <img :src="getCDNpath('assets/img/ui/video-bg.jpg')">
        </div>
    </article>
  </transition>
</template>

<script>
export default {
  props: [
    "properties",
    "pageData",
    "slug",
    "componentID"
  ],
  data() {
    return {
      ready: false,
      youtubeID: '',

      // guard
      isIPhone: false,
      positionData: {},
      debugAsIPhone: false,
    }
  },
  created() {
    if (this.properties.embedURL !== '') {
      this.youtubeID = this.getYoutubeID(this.properties.embedURL);
    }

    
    if(/iPhone/.test(navigator.userAgent) || this.debugAsIPhone)
      this.isIPhone = true;
  },
  mounted() {
    this.ready = true
  },
  methods: {
    getYoutubeID(url) {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        var match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    },
    openContent(contentID) {
        app.api.PageManager.openContentComponentFromID(contentID);
    },
    closeComponent() {
      this.$emit("closeComponent", this.slug)
    },
    getCDNpath(asset) {
      return app.api.Utils.getCDNpath(asset)
    }
  },
  computed: {
    isYoutubeLink() {
      return !!this.getYoutubeID(this.properties.embedURL);
    },
    embedURL() {
      if (this.isYoutubeLink) {
        return `https://www.youtube.com/embed/${this.youtubeID}`;
      } else {
        return this.properties.embedURL; // Use the original URL if it's not a YouTube link
      }
    },
    getComponentTitle() {
      let pages = app.api.PageManager.getAllPages()
      let data = pages.find(page => page.id === this.componentID)

      return data ? data.title : ""
    },
    hasGuard() {
      return Boolean(this.properties.guardContentID && this.properties.guardCaption);
    },
  }
};
</script>

<style lang="scss" scoped>

iframe { 
  height: 740px;
}
  

.guard {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  font-size: 26px;

  .Hotspot {
    position: relative;
  }
}
</style>