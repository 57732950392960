<template>
    <router-link :to="homeURL" id="home-button" class="btnfx btnfx__clickflash btnfx__hoverscale">
        <img src="@/assets/img/ui/home.png">
    </router-link>
</template>
<script>
export default {
    computed: {
        homeURL() {
            return app.api.PageManager.getRootPage().route
        }
    }
}
</script>
<style lang="scss" scoped>
#home-button {
    position: absolute;
    width:50px;
    height:50px;
    border-radius: 50%;
    background-color: var(--main-color);
    top: 40px;
    left: 40px;
    z-index: 2;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>