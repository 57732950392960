<template>
  <transition name="toggleContentComponent">
    <article class="textImage" v-if="ready">
      <!-- TOPBAR -->
      <nav class="textImage__topbar">
        <button class="button__close btnfx btnfx__hoverscale" @click="closeComponent">
          <img src="@/assets/img/ui/close_circle.png" />
        </button>
        <span @click="closeComponent">Close</span>
      </nav>

      <!-- CONTENT -->
      <div class="textImage__center">
        <h3 v-html="properties.title"></h3>
        <div class="text-wrapper">
          <div v-html="properties.text"></div>
          <div v-html="properties.textTwo"></div>
        </div>
        
        <div v-if="properties.links" class="textImage__center_links">
          <template v-for="link in properties.links" :key="link.title">
            <button v-if="link.link" @click="openLink(link.link)">
              <div>
                <img v-if="link.icon" :src="getSrc(link.icon.src)">
                <img v-else src="@/assets/img/ui/plus.png">
              </div>
              <h6 v-html="link.title"></h6>
              <span v-html="link.subtitle"></span>
            </button>
            <a v-else-if="link.linkToFile" :href="getSrc(link.linkToFile.src)" download target="_blank">
              <div>
                <img v-if="link.icon" :src="getSrc(link.icon.src)">
                <img v-else src="@/assets/img/ui/plus.png">
              </div>
              <h6 v-html="link.title"></h6>
              <span v-html="link.subtitle"></span>
            </a>
          </template>
        </div>
      </div>

      <div class="textImage__center_image right" v-if="properties.rightImage"><img :src="getSrc(properties.rightImage.src)"></div>
      <div class="textImage__center_image bottom" v-if="properties.bottomImage"><img :src="getSrc(properties.bottomImage.src)"></div>

      <div class="textImage__background">
        <img :src="getCDNpath('assets/img/ui/textimg-bg.jpg')">
      </div>

    </article>
  </transition>
</template>

<script>
export default {
  props: ["properties"],
  data() {
    return {
      ready: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.ready = true;
    }, 1);
  },
  computed: {
  },
  methods: {
    closeComponent() {
      this.ready = false;
      app.api.PageManager.closeAllContentComponents();
    },

    openLink(id) {
      if (id) 
        app.api.PageManager.openContentComponentFromID(id, false);
    },

    getSrc(src) {
      return app.api.Utils.getMediaPath(src);
    },

    getCDNpath(asset) {
      return app.api.Utils.getCDNpath(asset)
    },
  }
}
</script>

<style lang="scss">
.textImage {
  
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 927px;
  bottom: 0;
  color: #fff;
  
  &__topbar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    width: 100%;
    padding: 30px 108px 0;

    .button__close {
      left: 40px;
      margin-right: 30px;
    }

    span {
      cursor: pointer;
      font-size: 22px;
      text-transform: uppercase;
      margin-right: auto;
      margin-bottom: 6px; // better centering fix
    }
  }

  
  &__center {
    text-align: left;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 1010px;
    height: 100%;
    padding: 30px 0 30px 108px;

    h3 {
      font-size: 30px;
      line-height: 1.1;
      font-weight: bold;
      margin-bottom: 16px;
    }

    .text-wrapper {
      line-height: 26px;
      font-weight: 200;
      margin-bottom: 40px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: flex-start;
      gap: 50px;
      div p:last-child { margin-bottom: 0; }
    }

    &_links {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;

      button, a {
        position: relative;
        text-align: left;
        padding: 0 30px 0 100px;
        height: 52px;
        padding-left: 66px;

        & > div {        
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          left: 0;
          top: 1px;
          height: 50px;
          width: 50px;
          min-width: 50px;
          background-color: var(--main-color);
          background-clip: padding-box;
          border: 4px solid var(--main-color-transparent);
          border-radius: 50%;
          color: var(--main-color);
          transition: transform 0.3s var(--tk-easeinout);

          img {
            width: 60%;
          }
        }

        h6 {
          font-weight: bold;
          font-size: 20px;
        }
        span {
          font-size: 15px;
        }

        &:hover {
          & div {
            transform: scale(1.1);
          }
        }
      }
    }

    &_image {
      position: absolute;
      aspect-ratio: 1 / 1;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.right {
        width: 430px;
        left: calc(50% + 455px);
        top: 147px;
      }

      &.bottom {
        width: 726px;
        left: calc(50% - 186px);
        top: 512px;

        img { padding-bottom: 310px; }
      }
    }

  }

  // background
  &__background {
    position: absolute;
    inset: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

}
</style>