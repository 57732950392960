<template>
    <nav id="Breadcrumbs">
        <router-link v-if="lastCrumb" :to="lastCrumb.route" :key="lastCrumb.route" v-html="lastCrumb.title"></router-link>
    </nav>
</template>
<script>
export default {
    computed: {
        lastCrumb() {
            this.$route;
            var breadcrumbs = app.api.PageManager.getBreadcrumbs().filter(crumb => crumb !== undefined && crumb.type !== "content")
            return (breadcrumbs.length>1) ? breadcrumbs[breadcrumbs.length-1]: null;
        }
    }
}
</script>
<style lang="scss">
#Breadcrumbs {
    position: absolute;
    display: flex;
    top: 40px;
    left: 156px;
    color: var(--dark-blue);
    max-width: var(--header-max-width);
    padding: var(--content-padding);
    pointer-events: none;
    font-size: 35px;
    line-height: 50px;
    z-index: 2;

    a {
        font-weight: normal;
        padding: 0 10px;
        pointer-events: all;
        position: relative;

        &::after {
            content: '/';
            position: absolute;
            pointer-events: none;
            margin-left: 4px;
            color: var(--dark-blue);
        }

        &:last-of-type {
            cursor: default;
            color: var(--dark-blue);

            &::after {
                content: none;
            }
        }

        &:hover {
            color: var(--main-color);
        }
    }
}
</style>