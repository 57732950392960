<template>
  <li>
    <div>
      <img src="@/assets/img/ui/chat-comment-like.png">
      <div>
        <span>{{formattedDate}}</span>
        <button @click="removeNews"><img src="@/assets/img/ui/close_blue.png"></button>
      </div>
    </div>
    <h3>{{news.title}}</h3>
    <p></p>
    <button @click="openDialogueCards">
      <span>{{translations.newsboardView}}</span>
    </button>
  </li>
</template>

<script>


export default {
  props: [
    "news",
    "formattedDate",
    "translations",
    "customData"
  ],
  methods: {
    openDialogueCards() {
      app.api.PageManager.navigateToRoute(app.api.PageManager.getRouteFromIDs(this.dialogueCardsScene, this.customData.messageBoardData.id));
    },
    removeNews() {
      this.$emit("removeNews", this.news)
    }
  },
  computed: {
    dialogueCardsScene() {
      return this.translations.newsboardChatsScene
    }
  }
};
</script>