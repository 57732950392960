<template>
  <transition name="toggleContentComponent">
    <article class="posterVideo" v-if="ready">
      <!-- TOPBAR -->
      <nav class="posterVideo__topbar">
        <button class="button__close btnfx btnfx__hoverscale" @click="closeComponent">
          <img src="@/assets/img/ui/close_circle.png" />
        </button>
        <span @click="closeComponent" v-html="properties.close || 'Close'"></span>
      </nav>

      <!-- CONTENT -->
      <div class="posterVideo__center">

        <div class="posterVideo__center_info">
          <h3 v-html="properties.title"></h3>
          <div v-html="properties.text"></div>
          <a :href="properties.posterPDF ? getImagePath(properties.posterPDF.src) : getImagePath(properties.poster.src)" download target="_blank">
            <div> <img src="@/assets/img/ui/download-small.png"> </div>
            <h6 v-html="properties.downloadTitle || 'Download poster'"></h6>
            <span v-html="properties.downloadText || 'To read more'"></span>
          </a>
          <button v-if="properties.link" @click="openLink(properties.link)">
            <div><img src="@/assets/img/ui/globe.png"> </div>
            <h6 v-html="properties.linkTitle || 'Want more info?'"></h6>
            <span v-html="properties.linkText || 'Follow link'"></span>
          </button>
        </div>

        <div class="posterVideo__center_media">
          <ul class="posterVideo__center_media_tabs" v-if="properties.video">
            <li><button
              v-html="properties.tabPoster || 'See poster'"
              :class="{active : activeTab == 'poster'}"
              @click="activeTab = 'poster'; pauseVideo()"
            ></button></li>
            <li><button
              v-html="properties.tabVideo || 'Watch video'"
              :class="{active : activeTab == 'video'}"
              @click="activeTab = 'video'"
            ></button></li>
          </ul>

          <div class="posterVideo__center_media_content">

            <!-- POSTER -->
            <div v-show="activeTab !== 'video'" :class="{hasText : properties.posterText}">
              <div class="wrapper">
                <template v-if="properties.posterPDF">
                  <iframe :src="getImagePath(properties.posterPDF.src)" frameborder="0"></iframe>
                </template>
                <template v-else-if="properties.poster">
                  <img :src="getImagePath(properties.poster.src)">
                  <button @click="openModal = true" class="btnfx btnfx__clicklight btnfx__hoverhalo"><img src="@/assets/img/ui/expand.png"></button>
                </template>
              </div>
              <p class="subtext" v-if="properties.posterText" v-html="properties.posterText"></p>
            </div>

            <!-- VIDEO -->
            <div v-if="properties.video" v-show="activeTab === 'video'" :class="{hasText : properties.videoText}">
              <div class="wrapper video" :class="{'videopaused': isVideoPaused}">
                <video
                  ref="video"
                  :poster="posterUrl"
                  type="video/mp4"
                  @playing="isVideoPaused = false"
                  @pause="isVideoPaused = true;"
                  controls
                  playsinline
                  webkit-playsinline
                  crossorigin 
                  disableRemotePlayback 
                  disablePictureInPicture>
                  <source :src="videoUrl">
                </video>
              </div>
              <p class="subtext" v-if="properties.videoText" v-html="properties.videoText"></p>
            </div>

          </div>
        </div>

        <!-- POSTER MODAL -->
        <transition name="toggleContentComponent">
          <div v-if="properties.poster" class="posterVideo__modal" :style="[getMaximizedSizeAndOffset]" v-show="openModal">
            <img :src="getImagePath(properties.poster.src)">
            <button @click="openModal = false" class="btnfx btnfx__clicklight btnfx__hoverhalo"><img src="@/assets/img/ui/close_white.png"></button>
          </div>
        </transition>

      </div>

      <div class="posterVideo__background">
        <img :src="getCDNpath('assets/img/ui/poster-bg.jpg')">
      </div>

    </article>
  </transition>
</template>
<script>
export default {
  props: ["properties"],
  data() {
    return {
      ready: false,
      isVideoPaused: true,
      activeTab: 'poster',
      openModal: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.ready = true;
    }, 1);
  },
  computed: {
    videoUrl() {
      return app.api.Utils.getMediaPath(this.properties.video.src)
    },
    posterUrl() {
      return this.properties.thumbnail ? app.api.Utils.getMediaPath(this.properties.thumbnail.src) : null;
    },
    getMaximizedSizeAndOffset() {
      return this.$store.getters.getMaximizedSizeAndOffsetValue;
    },
  },
  methods: {
    openLink(id) {
      if (id) 
        app.api.PageManager.openContentComponentFromID(id, false);
    },
    pauseVideo() {
      this.$refs.video.pause();
    },
    closeComponent() {
      this.ready = false;
      app.api.PageManager.closeAllContentComponents();
    },
    
    getImagePath(src) {
      return app.api.Utils.getMediaPath(src);
    },
    getCDNpath(asset) {
      return app.api.Utils.getCDNpath(asset)
    },
  }
}
</script>

<style lang="scss">
.posterVideo {
  // én stil for alle blå knapper
  %button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    background-color: var(--main-color);
    background-clip: padding-box;
    border: 4px solid var(--main-color-transparent);
    border-radius: 50%;
    color: var(--main-color);
    transition: transform 0.3s var(--tk-easeinout);

    img { width: 50%; }
  }
  
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 927px;
  bottom: 0;
  color: #fff;
  
  &__topbar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    width: 100%;
    padding: 30px 108px 0;

    .button__close {
      left: 40px;
      margin-right: 30px;
    }

    span {
      cursor: pointer;
      font-size: 22px;
      text-transform: uppercase;
      margin-right: auto;
      margin-bottom: 6px; // better centering fix
    }
  }

  
  &__center {
    text-align: left;
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: 400px auto;
    align-items: center;
    gap: 140px;
    padding: 30px 108px;

    &_info {
      
      h3 {
        font-size: 30px;
        line-height: 1.1;
        font-weight: bold;
        margin-bottom: 16px;
      }

      > div {
        line-height: 26px;
        font-weight: 200;
        margin-bottom: 40px;
      }

      button, a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        height: 50px;
        padding-left: 66px;
        margin-top: 20px;

        > div {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          left: 0;
          top: 0;
          height: 50px;
          width: 50px;
          min-width: 50px;
          background-color: var(--main-color);
          background-clip: padding-box;
          border: 4px solid var(--main-color-transparent);
          border-radius: 50%;
          color: var(--main-color);
          transition: all 0.3s var(--tk-easeinout);

          img {
            width: 60%;
          }
        }

        h6 {
          font-weight: bold;
          font-size: 20px;
          line-height: 1.2;
        }
        span {
          font-size: 15px;
        }

        &:hover {
          & div {
            transform: scale(1.1);
          }
        }
      }
      a > div img { width: 20px; } // quick fix - icon width

    }

    &_media {
      align-self: start;
      max-height: 787px;

      &_tabs {
        position: absolute;
        transform: translateX(calc(-100% + 1px));
        list-style: none;
        padding: 0;
        margin: 0;
        min-width: 161px;

        li { margin-bottom: 0; }

        button {
          color: var(--light-grey);
          background-color: var(--main-color);
          padding: 12px 0 12px 26px;
          width: 100%;
          border-radius: 30px 0 0 30px;
          margin-bottom: 8px;

          &.active {
            color: var(--dark-blue);
            background-color: var(--light-grey);
          }
        }
      }

      &_content {
        position: relative;
        z-index: 2;

        .wrapper {
          background-color: var(--dark-blue);
          position: relative;
          aspect-ratio: 16 / 9;
          max-height: 787px;
          max-width: 100%;

          img, iframe {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          video {
            display: block;
            width: 100%;
            height: 100%;
          }
          button {
            @extend %button;
            position: absolute;
            bottom: 12px;
            right: 12px;
          }

          &.video {
            &::after {
              content: none;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translateX(-50%) translateY(-50%);
              width: 20px;
              height: 34px;
              pointer-events: none;
              background-image: url(@/assets/img/ui/play_large.png);
              background-repeat: no-repeat;
              background-size: 100%;
              background-position: center;
              z-index: 3;
              margin-left: 1px;
            }
            
            &::before {
              content: none;
              pointer-events: none;
              position: absolute;
              z-index: 3;
              left: 50%;
              top: 50%;
              transform: translateX(-50%) translateY(-50%);
              width: 80px;
              height: 80px;
              background: var(--main-color);
              background-clip: padding-box;
              border: 5px solid var(--main-color-transparent);
              border-radius: 80px;
            }

           &.videopaused {
                video {
                    cursor: pointer;
                }

                &::after,&::before {
                    content: '';
                }
            }
          }
        }

        .subtext { margin: 10px 0 0; }

        > div.hasText .wrapper { max-height: 752px; }
        > div:first-of-type .wrapper { background-color: var(--light-grey); }
      }
    }
  }

  // background
  &__background {
    position: absolute;
    inset: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center left;
    }
  }

  .button__close {
    top: 40px;
    left: 40px;
  }

  &__modal {
    background-color: var(--dark-blue);
    position: fixed;
    top: 153px !important; // undskyld!
    bottom: 0;
    z-index: 10;
    aspect-ratio: 16 / 9;
    max-height: unset;
    height: calc(100% - 153px) !important;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    button {
      @extend %button;
      position: absolute;
      top: 24px;
      right: 24px;
    }
  }
}

/* Modal Transition */
.togglePosterModal-enter-active {
  transition-property: transform, opacity;
  transition-duration: 0.4s;
  transition-timing-function: var(--tk-easeinout);
}

.togglePosterModal-leave-active {
  transition-property: transform, opacity;
  transition-duration: 0.4s;
  transition-timing-function: var(--tk-easeinout);
}

.togglePosterModal-enter-from {
  transform: translateY(40px);
  opacity: 0;
}

.togglePosterModal-leave-to {
  transform: translateY(40px);
  opacity: 0;
}
</style>