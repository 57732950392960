<template>
    <nav class="Hotspot dropdownHotspot" :class="{'dropdownHotspot--open': dropdownOpen}" :style="[getPosition]" >
        <button @click="toggleDropdown">
            <span v-html="getHotspotTitle"></span>
        </button>
        <ul v-if="dropdownOpen">
            <router-link v-for="link in links" :key="link.title" :to="link.route" @click="closeDropdown">{{link.title}}</router-link>
        </ul>
    </nav>
</template>

<script>
export default {
    props: ['hotspot'],
    data() {
        return {
            dropdownOpen: false,
            links: []
        }
    },
    created() {
        this.prepareLinks();
    },
    watch: {
        $route() {
            this.dropdownOpen = false;
        }
    },
    methods: {
        prepareLinks() {
            this.links = this.hotspot.data.links.map(link => {
                link.route = app.api.PageManager.getRouteFromIDs(link.scene, link.content)
                return link;
            })
        },
        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen;
        },
        closeDropdown() {
            this.dropdownOpen = false;
        }
    },
    computed: {
        getPosition() {
            return {
                left: this.hotspot.data.positionData.xPosition + 'px',
                top: this.hotspot.data.positionData.yPosition + 'px'
            }
        },
        getHotspotTitle() {
            return this.hotspot.data.alternateTitle && this.hotspot.data.alternateTitle !== '' ? this.hotspot.data.alternateTitle : this.hotspot.data.title;
        },
    }
}
</script>


<style lang="scss">
.dropdownHotspot {
    color: var(--main-color);
    min-width: 292px;
    text-align: left;
    user-select: none;

    &>button {
        min-width: 100%;
        background: #fff;
        border-radius: 1000px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 24px 14px 24px;
        border-bottom: solid 1px transparent;

        &::after {
            content: "";
            width: 11px;
            height: 7px;
            background-image: url(@/assets/img/ui/arrow_dropdown.png);
            background-size: cover;
            background-repeat: no-repeat;
            transition: var(--tk-easeinout) 0.2s transform;
        }
    }

    ul {
        margin-bottom: 0;
        padding-left: 0;
        a,li {
            display: block;
            width: 100%;
            padding: 14px 24px 14px 24px;
            background: #fff;
            border-bottom: solid 1px var(--dark-blue);
            transition-property: background, color;
            transition-duration: 0.15s;
            transition-timing-function: ease;

            &:last-of-type {
                border-radius: 0 0 25px 25px;
                border-bottom: none;
            }

            &:hover {
                background: var(--main-color);
                color: #fff;
            }
        }
    }

   &--open {
       z-index: 2;
       &>button {
           border-radius: 25px 25px 0 0;
           border-bottom: solid 1px var(--dark-blue);
           
           &::after {
               transform: rotate(180deg);
            }
        }
   }

   &--active {
       button {
           color: var(--red);
       }
   }
}
</style>