<template>
    <nav class="exhibitionScreens__nav">
        <button @click="changePagePrevious"><img src="@/assets/img/ui/arrow_programme.png"></button>
        <div class="exhibitionScreens__nav__indicators">
            <button class="exhibitionScreens__nav__indicators_button" v-for="(button, i) in pages" :key="button.text" @click="changePage(i)" :class="{'exhibitionScreens__nav__indicators_button--active': activeGroup === i}"></button>
        </div>
        <button @click="changePageNext"><img src="@/assets/img/ui/arrow_programme.png"></button>
    </nav>
</template>

<script>
export default {
    props: [
        "activeGroup",
        "pages"
    ],
    data() {
        return {
            
        }
    },
    methods: {
        changePagePrevious() {
            if(this.activeGroup > 0) {
                this.changePage(this.activeGroup - 1) 
            } else {
                this.changePage(this.pages.length - 1) 
            }
        },
        changePageNext() {
            if(this.activeGroup < this.pages.length - 1) {
                this.changePage(this.activeGroup + 1) 
            } else {
                this.changePage(0) 
            }
        },
        changePage(i) {
            this.$emit("changePage", i)
        }
    }
}
</script>

<style lang="scss">
.exhibitionScreens__nav {
    position: absolute;
    display: flex;
    align-items: center;
    top: calc(100% + 80px);
    left: 50%;
    transform: translateX(-50%);
    height: 54px;
    background: rgba(255, 255, 255, 0.47);
    border-radius: 1000px;
    padding: 22px;

    & > button {
        position: relative;

        img {
            height: 17px;
            transition-property: transform;
            transition-duration: 0.15s;
            transition-timing-function: var(--tk-easeinout);
        }

        &::before {
            content: '';
            position: absolute;
            width: 280%;
            height: 170%;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
        }

        &:first-of-type {
            img {
                transform: scaleX(-1);
            }
        }

        &:hover {
            img {
                transform: translateX(3px);
            }
            
            &:first-of-type {
                img {
                    transform: scaleX(-1) translateX(3px);
                }
            }
        }
    }

    &__indicators {
        display: flex;
        align-items: center;
        margin: 0 60px;

        &_button {
            position: relative;
            width: 12px;
            height: 12px;
            border-radius: 1000px;
            border: solid 2px var(--dark-blue);
            margin-right: 55px;
            transition-property: transform background;
            transition-duration: 0.15s;
            transition-timing-function: var(--tk-easeinout);

            &::after {
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
                width: 500%;
                height: 500%;
            }

            &:last-of-type {
                margin-right: 0;
            }
            
            &--active {
                background: var(--dark-blue);
            }

            &:hover {
                transform: scale(1.2);
                background: var(--dark-blue);
            }
        }
    }
}
</style>