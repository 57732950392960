<template>
  <li>
    <div>
      <img src="@/assets/img/ui/calendar-of-events.png">
      <div>
        <span>{{formattedDate}}</span>
        <button @click="removeNews"><img src="@/assets/img/ui/close_blue.png"></button>
      </div>
    </div>
    <h3>{{news.title}}</h3>
    <p>{{news.subtitle}}</p>
    <button @click="gotoEventCalendar">
      <span>{{translations.newsboardView}}</span>
    </button>
  </li>
</template>

<script>


export default {
  props: [
    "news",
    "formattedDate",
    "translations",
    "customData"
  ],
  data() {
    return {
      
    }
  },
  mounted() {
  },
  methods: {
    gotoEventCalendar() {
      var route = app.api.PageManager.getRouteFromID(this.eventCalendarScene);
      var contentslug = app.api.PageManager.getContentComponentFromID(this.eventCalendarContent).slug 
      app.api.PageManager.navigateToRoute(route + ":" + contentslug);
    },
    removeNews() {
      this.$emit("removeNews", this.news)
    }
  },
  unmounted() {
  },
  computed: {
    eventCalendarScene() {
      return this.translations.eventCalendarLink.sceneID
    },
    eventCalendarContent() {
      return this.translations.eventCalendarLink.contentID
    }
  }
};
</script>

<style lang="scss" scoped>

</style>