<template>
  <nav id="UiLayer"  :style="[getMaximizedSizeAndOffset]">
      <BackButton :class="{ 'fade-out': this.onHomePage }"></BackButton>
      <HomeButton :class="{ 'fade-out': this.onHomePage }"></HomeButton>
      <Breadcrumbs></Breadcrumbs>
      <Logo v-show="!hasLargeContentComponentOpen"></Logo>
      <Newsboard v-show="!hasFullscreenContentComponent && !hideBottomUiInScenes"></Newsboard>
      <Leaderboard v-show="!hasFullscreenContentComponent && !hasLargeContentComponentOpen && !hideBottomUiInScenes"></Leaderboard>
      <CalendarButton v-show="!hasFullscreenContentComponent && !hasLargeContentComponentOpen && !hideBottomUiInScenes"></CalendarButton>
  </nav>
</template>
<script>
import BackButton from "../ui/BackButton.vue";
import HomeButton from "../ui/HomeButton.vue";
import Breadcrumbs from "../ui/Breadcrumbs.vue";
import Logo from "../ui/Logo.vue";
import Newsboard from "../ui/Newsboard.vue";
import Leaderboard from '../ui/Leaderboard.vue';
import CalendarButton from '../ui/CalendarButton.vue';

export default {
  components: {
    // Ui Components
    BackButton,
    HomeButton,
    Breadcrumbs,
    Logo,
    Newsboard,
    Leaderboard,
    CalendarButton
  },
  data() {
    return {
    };
  },
  methods: {

  },
  computed: {
    onHomePage() {
      this.$route;
      return app.api.PageManager.onHomePage();
    },
    hasLargeContentComponentOpen() {
      this.$route
      var hasLargeContentComponent = false;
      if (!app.api.PageManager.getIsContentComponentOpen())
        return hasLargeContentComponent

      var largeComponents = ["chatRoom"]
      var openComponents = app.api.PageManager.getOpenContentComponents()
      var i = 0;

      while (i < openComponents.length) {
        if (largeComponents.includes(openComponents[i].type)) {
          hasLargeContentComponent = true
        }
        i++
      }

      return hasLargeContentComponent
    },
    hasFullscreenContentComponent() {
      this.$route
      var hasFullscreenComponent = false;
      if (!app.api.PageManager.getIsContentComponentOpen())
        return hasFullscreenComponent

      var fullscreenComponents = ["programme", "fullscreenVideo", "fullscreenIframePlayer", "videoLibrary", "posterVideo", "textImage", "expertAreasConnect"];
      var openComponents = app.api.PageManager.getOpenContentComponents()
      var i = 0;

      while (i < openComponents.length) {
        if (fullscreenComponents.includes(openComponents[i].type)) {
          hasFullscreenComponent = true
        }
        i++
      }

      return hasFullscreenComponent
    },
    hideBottomUiInScenes() {
      this.$route;
      var hideInThisScene = false;
      app.api.PageManager.getCustomComponentFromType("uIText").data.hideBottomUiInScenes.forEach(scene => {
        if(app.api.PageManager.getRouteFromIDs(scene.sceneIDPick, false) == app.api.PageManager.getCurrentRoute())
          hideInThisScene = true
      })

      return hideInThisScene

    },
    getMaximizedSizeAndOffset() {
      return this.$store.getters.getMaximizedSizeAndOffsetValue;
    },
  }
};
</script>

<style lang="scss">
#UiLayer {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  
  * {
    pointer-events: all;
  }

  .fade-out {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.5s;
    transition-delay: 0.5s;
    pointer-events: none;

    *{
      pointer-events: none;
    }
  }

  &.hidden {
    opacity: 0;

    * {
      pointer-events: none;
    }
  }
}
</style>