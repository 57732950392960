<template>
  <div class="expertAreasConnect__circle" :class="{'expertAreasConnect__circle--hasShared': hasShared}">
    <svg :viewBox="svgViewport">
      <!-- Draw lines grouped -->
      <g ref="linesGroup">
      </g>
        <line v-if="selectedLeftPoint && selectedRightPoint" :x1="selectedLeftPoint.x" :y1="selectedLeftPoint.y" :x2="selectedRightPoint.x" :y2="selectedRightPoint.y" id="userSvgLine" class="svgLine" stroke="#e6553f" stroke-width="0.8"></line>
      <!-- Draw points for the left half circle -->
      <circle v-for="point in leftPoints" :key="point.title"
        :cx="point.x" :cy="point.y" r="1" fill="#fff" stroke="#001965" stroke-width="0.3" @click="pointClicked(point, 'region')" :class="{'circle--active': point.title === selectedRegion}"/>
      <!-- Draw points for the right half circle -->
      <circle v-for="(point, index) in rightPoints" :key="'right-' + index"
        :cx="point.x" :cy="point.y" r="1" fill="#fff" stroke="#001965" stroke-width="0.3" @click="pointClicked(point, 'function')" :class="{'circle--active': point.title === selectedFunction}"/>
    </svg>
    <div class="expertAreasConnect__circle__ui">
        <div class="expertAreasConnect__circle__ui_region">
            <span v-for="label in leftPoints" v-html="label.title" :key="label.title" :style="getLabelPosition(label)" @click="pointClicked(label, 'region')" :class="{'expertAreasConnect__circle__ui_region_label--active': label.title === selectedRegion}"></span>
        </div>
        <div class="expertAreasConnect__circle__ui_function">
            <div v-for="label in rightPoints" :key="label.title" :style="getLabelPosition(label)" @click="pointClicked(label, 'function')" :class="{'expertAreasConnect__circle__ui_region_label--active': label.title === selectedFunction}">
                <h6 v-html="label.title"></h6>
                <span v-html="label.subtitle"></span>
            </div>
        </div>
    </div>
    <img src="@/assets/img/ui/expertareasconnect-bg.png">
  </div>
</template>

<script>
export default {
    props: [
        "leftCircle",
        "rightCircle",
        "selectedRegion",
        "selectedFunction",
        "hasShared",
        "lines"
    ], 
    data() {
        return {
            svgViewportValue: 100,
            radius: 33.4,
            leftPoints: [],
            rightPoints: [],
            lineDelay: 100
        };
    },
    created() {
        this.createPoints()
    },
    mounted() {
        this.createLines();
        //create any number of random lines between the points (can not exceed the maximum amount possible, so the case of 7 points on each side, the max is 49)
        //this.createRandomLines(49);
    },
    methods: {
        createPoints() {
            this.calculatePointsLeft();
            this.calculatePointsRight();
        },
        calculatePointsLeft() {
            const startPosition = -1.35; // Define the position the circle section starts (adjust as needed)
            const maxCurve = 0.705; // Define the maximum angle of the curve (adjust as needed)

            const calculatedMaxCurve = Math.PI * maxCurve; 
            const angleStep = calculatedMaxCurve / (this.leftCircle.length - 1); 

            // reverse and array without messing with the prop for parent components... 
            let tempArray = []
            this.leftCircle.forEach(element => {
                tempArray.push(element)
            });
            tempArray.reverse()

            this.leftPoints = tempArray.map((point, i) => {
                const angle = startPosition * Math.PI + i * angleStep;
                const x = (this.svgViewportValue / 2) + this.radius * Math.cos(angle);
                const y = (this.svgViewportValue / 2) + this.radius * Math.sin(angle);

                return {title: point, x, y}
            })
        },
        calculatePointsRight() {
            const startPosition = 1.59;// Define the position the circle section starts (adjust as needed)
            const maxCurve = 0.823; // Define the maximum angle of the curve (adjust as needed)

            const calculatedMaxCurve = Math.PI * maxCurve;
            const angleStep = calculatedMaxCurve / (this.rightCircle.length - 1); 

            // reverse and array without messing with the prop for parent components... 
            let tempArray = []
            this.rightCircle.forEach(element => {
                tempArray.push(element)
            });

            this.rightPoints = tempArray.map((point, i) => {
                const angle = startPosition * Math.PI + i * angleStep;
                const x = (this.svgViewportValue / 2) + this.radius * Math.cos(angle);
                const y = (this.svgViewportValue / 2) + this.radius * Math.sin(angle); 

                return {title: point.title, subtitle: point.footprintText, x, y}
            })
        },
        createLines() {
            let delay = 0; // Initial delay

            // Loop through each line
            this.lines.forEach(line => {
                // Find the index of the left point based on the region in the line object
                const leftIndex = this.leftPoints.findIndex(point => point.title === line.region);

                // Find the index of the right point based on the function in the line object
                const rightIndex = this.rightPoints.findIndex(point => point.title === line.function);

                // Get the points corresponding to the indices
                const leftPoint = this.leftPoints[leftIndex];
                const rightPoint = this.rightPoints[rightIndex];

                // Draw SVG line between the points with delay
                this.drawSVGLine(leftPoint, rightPoint, delay);

                // Increase the delay for the next line
                delay += this.lineDelay;
            });
        },
        createRandomLines(lines) {
            const drawnPairs = new Set(); // Set to store pairs of drawn indices

            let delay = 0; // Initial delay

            while (drawnPairs.size < lines) {
                // Generate random indices for the left and right circles
                const leftIndex = Math.floor(Math.random() * this.leftPoints.length);
                const rightIndex = Math.floor(Math.random() * this.rightPoints.length);
                const pair = `${leftIndex}-${rightIndex}`;

                // Check if the pair has already been drawn
                if (!drawnPairs.has(pair)) {
                    // Add the pair to the set of drawn pairs
                    drawnPairs.add(pair);

                    // Get the points corresponding to the random indices
                    const leftPoint = this.leftPoints[leftIndex];
                    const rightPoint = this.rightPoints[rightIndex];

                    // Draw SVG line between the points with delay
                    this.drawSVGLine(leftPoint, rightPoint, delay);

                    // Increase the delay for the next line
                    delay += this.lineDelay;
                }
            }
        },
        drawSVGLine(leftPoint, rightPoint, delay) {
            setTimeout(() => {
                // Create SVG line element
                const line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
                line.setAttribute('x1', leftPoint.x);
                line.setAttribute('y1', leftPoint.y);
                line.setAttribute('x2', rightPoint.x);
                line.setAttribute('y2', rightPoint.y);
                line.classList.add('svgLine')
                line.setAttribute('stroke', '#005AD2');
                line.setAttribute('stroke-width', '0.2');

                // Append the line to the SVG element using the ref
                if(this.$refs.linesGroup)
                    this.$refs.linesGroup.appendChild(line);
            }, delay);
        },
        getLabelPosition(label) {
            const yOffsetMax = 1.9; // Max offset in percentage
            const distanceFromCenter = Math.abs(label.y - 50); // Calculate distance from center

            let offsetPercentage = 0;

            // Check if label is within the range where no offset is applied
            if (distanceFromCenter >= 2) {
                // Calculate offset percentage based on distance from center
                offsetPercentage = Math.min((distanceFromCenter / 50) * 100, yOffsetMax);
            }

            // Calculate final Y position
            const finalY = label.y > 50 ? label.y + offsetPercentage : label.y - offsetPercentage;

            return { 
                left: `${label.x}%`,
                top: `${finalY}%`
            };
        },

        pointClicked(point, type) {
            var payload = {
                type: type,
                point: point.title
            }

            this.$emit("pointClicked",payload)
        }
    },
    computed :{
        svgViewport() {
            return `0 0 ${this.svgViewportValue} ${this.svgViewportValue}`
        },
        selectedLeftPoint() {
            return this.leftPoints.find(point => point.title === this.selectedRegion)
        },
        selectedRightPoint() {
            return this.rightPoints.find(point => point.title === this.selectedFunction)
        }
    }
};
</script>

<style lang="scss">
$svg-width: 1000px;
$svg-height: 1000px;


.expertAreasConnect__circle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &> svg {
    width: $svg-width;
    height: $svg-height;

    circle {
        position: relative;
        cursor: pointer;
        transition: fill ease 0.15s;

        &.circle--active {
            fill: var(--red);
        }

        &:hover {
            fill: var(--red);
        }
    }
  }

    &__ui {
        position: absolute;
        width: $svg-width;
        height: $svg-height;
        pointer-events: none;
        
        &>div {
            position: absolute;
            height: 100%;
            width: 100%;
        }

        &_region {
            span {
                position: absolute;
                transform: translateX(calc(-100% + -32px)) translateY(-53%);
                font-size: 16px;
                font-weight: bold;
                color: var(--dark-blue);
                pointer-events: all;
                cursor: pointer;
                transition: color ease 0.15s;
                user-select: none;

                &.expertAreasConnect__circle__ui_region_label--active {
                    color: var(--red);
                }

                &:hover {
                    color: var(--red);
                }
            }
        }

        &_function {
            &>div {
                position: absolute;
                text-align: left;
                user-select: none;
                pointer-events: all;
                cursor: pointer;
                transition: color ease 0.15s;
                white-space: nowrap;
                transform: translateX(32px) translateY(-53%);

                h6 {
                    font-size: 16px;
                    font-weight: bold;
                    color: var(--dark-blue);
                }

                span {
                    color: var(--main-color);
                }

                &.expertAreasConnect__circle__ui_region_label--active {
                    h6 {
                        color: var(--red);
                    }
                }

                &:hover {
                    h6 {
                        color: var(--red);
                    }
                }
            }
        }
    }

  &> img {
    position: absolute;
    //margin-bottom: -9px;
    z-index: -1;
    pointer-events: none;
    opacity: 1;   
  }

  &--hasShared { 
      pointer-events: none !important;
      .expertAreasConnect__circle__ui_function > div, .expertAreasConnect__circle__ui_region span {
          pointer-events: none;
      }
  }
}

.svgLine {
    stroke-dasharray: 75;
    stroke-dashoffset: 75;
    animation: drawLine 2.5s var(--tk-easeinout) forwards;
    
    transition: opacity 0.5s ease;
}

@keyframes drawLine {
  from {
    stroke-dashoffset: 75;
  }
  to {
    stroke-dashoffset: 0;
  }
}
</style>