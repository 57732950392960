<template>
    <div class="exhibitionScreens__group__screen">
        <router-link :to="getComponentRoute" class="Hotspot defaultHotspot btnfx" v-if="screen !== null">
            <div class="defaultHotspot__button btnfx__clicklight btnfx__hoverhalo"></div>
        </router-link>
        <img v-if="screen !== null" :src="posterImage">
    </div>
</template>

<script>
export default {
    props: ["screen"],
    created() {
        /* console.log(this.screen) */
    },
    computed: {
        posterImage() {
            if(this.screen !== null)
                return app.api.Utils.getMediaPath(this.screen.poster.src)
        },
        getComponentRoute() {
            if(this.screen !== null) {
                return app.api.PageManager.getRouteFromIDs(null, this.screen.contentID)
            }
        }
    }
};
</script>

<style lang="scss">
.exhibitionScreens__group__screen {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
        object-fit: fill;
        max-width: 100%;
    }

    &--0 {
        background-image: url(@/assets/img/ui/exhibitionScreens/0.png);
    }
    &--1 {
        background-image: url(@/assets/img/ui/exhibitionScreens/1.png);
    }
    &--2 {
        background-image: url(@/assets/img/ui/exhibitionScreens/2.png);
    }
    &--3 {
        background-image: url(@/assets/img/ui/exhibitionScreens/3.png);
    }
    &--4 {
        background-image: url(@/assets/img/ui/exhibitionScreens/4.png);
    }
    &--5 {
        background-image: url(@/assets/img/ui/exhibitionScreens/5.png);
    }
}
    
</style>