<template>
    <article id="Leaderboard" :class="{'Leaderboard--open': leaderboardOpen}">
        <button class="Leaderboard__button" @click="openLeaderboard">
            <div class="Leaderboard__button_icon" v-if="!leaderboardOpen">
                <img src="@/assets/img/ui/star.png" >
            </div>
            <div class="Leaderboard__button_close btnfx btnfx__clicklight btnfx__hoverhalo" v-else>
              <img src="@/assets/img/ui/close_white.png" >
            </div>
            <span v-show="!leaderboardOpen">{{ displayedPoints }}</span>
        </button>
        
        <aside class="Leaderboard__window" v-if="leaderboardOpen">
            <h4>{{leaderboardTitle}}</h4>
            <h5>{{leaderboardSubtitle}}</h5>
            <div class="Leaderboard__window__top3">
                <div class="Leaderboard__button" v-for="(score, i) in topThree" :key="score.name">
                    <label>{{ i + 1 }}</label>
                    <div class="Leaderboard__button_icon">
                        <img src="@/assets/img/ui/star.png">
                    </div>
                    <span>{{score.name}}<br>{{ score.points }}</span>
                </div>
            </div>
            <ol start="4">
                <li v-for="score in topScoreboard" :key="score.name">
                    <span>{{score.name}}</span>
                    <div v-if="score.points > 0">{{score.points}}</div>
                </li>
                <li :style="{'counter-reset': `scoreboard-counter ${position - 1}`}">
                    <span>{{username}}</span>
                    <div>{{points}}</div>
                </li>
            </ol>
        </aside>
    </article>
</template>
<script>
export default {
  data() {
    return {
      username: "",

      leaderboardOpen: false,
      points: 0,
      position: 0,
      displayedPoints: 0,
      pointsAnimationInterval: false,

      scoreboard: [],
    };
  },
  async mounted() {
    await this.getUserName();
    await this.getPointsForUser();
    this.getScoreboard();

    window.addEventListener("addPoints", e => {
        var points = e.detail.points
        this.addPoints(points)
        setTimeout(() => {
          this.getScoreboard();
        }, 1000);
    });
  },
  watch: {
    points() {
      clearInterval(this.pointsAnimationInterval);

      if (this.points == this.displayedPoints) {
        return;
      }

      this.pointsAnimationInterval = window.setInterval(
        function () {
          if (this.displayedPoints != this.points) {
            var change = (this.points - this.displayedPoints) / 10;

            change = change >= 0 ? Math.ceil(change) : Math.floor(change);

            this.displayedPoints = this.displayedPoints + change;
          }
        }.bind(this), 80
      );
    },
  },
  methods: {
    async getUserName(){
      this.username = await MicroServiceAPI.GetUsername()
    },
    async getPointsForUser() {
      var fullHighscore = await MicroServiceAPI.GetPointsHighscoreListSummedAllSources(0);
      var playerScore = fullHighscore.entries.find(score => score.user == this.username)
      
      if(playerScore) {
        this.points = playerScore.pointsCount;
        this.position = playerScore.position;
      } else {
        await MicroServiceAPI.PostPoints({pointsCount: 0})
        this.getPointsForUser()
      }
    },
    async getScoreboard() {
        this.scoreboard = [];

        var scoreboardData = await MicroServiceAPI.GetPointsHighscoreListSummedAllSources(10);
        scoreboardData.entries.forEach(score => {
            let newScore = {
                name: score.user,
                points: score.pointsCount,
            }
            
            // Find the index to insert the new score based on pointsCount
            let index = this.scoreboard.findIndex(existingScore => newScore.points > existingScore.points);

            // If no such index is found, push it to the end
            if (index === -1) {
                this.scoreboard.push(newScore);
            } else {
                // Otherwise, insert it at the found index
                this.scoreboard.splice(index, 0, newScore);
            }
        })
    },

    async addPoints(points) {
        await MicroServiceAPI.PostPoints({pointsCount: points})
        this.getPointsForUser()
        app.api.AudioManager.playSpecificAudio("points")
    },
    openLeaderboard() {
      this.leaderboardOpen = !this.leaderboardOpen;
    },
    getCDNpath(asset) {
      return app.api.Utils.getCDNpath(asset);
    },
  },
  computed: {
    topThree() {
      return this.scoreboard.slice(0, 3);
    },
    topScoreboard() {
      let scoreboard = this.scoreboard.slice(3, 10)
      
      while(scoreboard.length < 7) {
        scoreboard.push({"name":"","points":0})
      }
      
      return scoreboard
    },
    leaderboardTitle() {
      return app.api.PageManager.getCustomComponentFromType("uIText").data.leaderboardTitle;
    },
    leaderboardSubtitle() {
      return app.api.PageManager.getCustomComponentFromType("uIText").data.leaderboardSubtitle;
    }
  },
};
</script>
<style lang="scss">
$LeaderboardButtonWidth: 88px;
$LeaderboardButtonHeight: 88px;

#Leaderboard {
  position: absolute;
  right: 0;
  bottom: 0;

  transition-property: transform;
  transition-duration: 0.25s;
  transition-timing-function: var(--tk-easeinout);

  > .Leaderboard__button {
    right: 42px;
    bottom: 172px;
    z-index: 2;
  }
}

.Leaderboard__button {
  position: absolute;
  transition-property: transform;
  transition-duration: 0.25s;
  transition-timing-function: var(--tk-easeinout);

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border-radius: 100%;
    left: 0;
    top: 0;
    background: var(--orange);
    width: 26px;
    height: 26px;
    font-size: 16px;
    font-weight: bold;
  }

  &_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $LeaderboardButtonWidth;
    height: $LeaderboardButtonHeight;
    background: var(--light-blue);
    border-radius: 100%;
    border: solid 6px #fff;

    & > img {
      transform-origin: 49% 53%;
      transition: transform 1.2s var(--tk-easeinout);
      width: 37px;
    }

    &::after {
      position: absolute;
      top: calc(100% - 40px);
      left: 50%;
      transform: translateX(-50%);
      content: "";
      background-image: url(@/assets/img/ui/leaderboard-ribbon.png);
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0.95;
      height: 88px;
      width: 58px;
      z-index: -1;
    }

    &::before {
      content: "";
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      box-shadow: var(--shadow-huge);
      transition-property: opacity;
      transition-duration: 0.35s;
      transition-timing-function: ease;
    }
  }

  &_close {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -28px;
    bottom: 38px;
    height: 50px;
    width: 50px;
    background-color: var(--main-color);
    background-clip: padding-box;
    border: 4px solid var(--main-color-transparent);
    border-radius: 50%;
    color: var(--main-color);
    transition: transform 0.3s var(--tk-easeinout);

    img { width: 18px; }
  }

  span {
    position: absolute;
    font-size: 16px;
    font-weight: bold;
    color: var(--dark-blue);
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
  }

  &:hover {
    transform: scale(1.01);
    .Leaderboard__button_icon {
      & > img {
        transform: rotate(430deg);
      }

      &::before {
        opacity: 0.3;
      }
    }
  }
}

.Leaderboard__window {
  position: absolute;
  width: 446px;
  height: 710px;
  right: 46px;
  top: -234px;
  transition-property: transform, opacity;
  transition-duration: 0.25s;
  transition-timing-function: var(--tk-easeinout);
  box-shadow: var(--shadow-huge);
  padding-top: 36px;
  color: #fff;
  opacity: 0;
  font-weight: lighter;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-color: var(--secondary-color);
    opacity: 0.95;
    z-index: -1;
  }

  h4 {
    font-size: 30px;
    margin-bottom: 12px;
  }

  h5 {
    font-size: 16px;
  }

  &__top3 {
    position: relative;
    height: 260px;

    .Leaderboard__button {
      bottom: auto;
      right: auto;
      &_icon {
        & > img {
          transform: rotate(0deg) !important;
          width: 42px !important;
        }
        &::after {
          top: calc(100% - 28px);
        }
      }
      span {
        line-height: 18px;
      }
    }

    > div:nth-of-type(1) {
      top: 32px;
      left: 40%;
    }
    > div:nth-of-type(2) {
      top: 80px;
      right: 66%;
    }
    > div:nth-of-type(3) {
      top: 80px;
      left: 66%;
    }
  }

  ol {
    padding-left: 0;
    list-style-type: none;
    counter-reset: scoreboard-counter 3;
    margin-bottom: 0;

    li {
      position: relative;
      counter-increment: scoreboard-counter;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 40px 0 70px;
      height: 42px;
      margin-bottom: 0;
      background: rgba(255, 255, 255, 0.2);
      overflow: hidden;

      & > div {
        position: relative;
        display: flex;
        align-items: center;

        &::after {
          content: "";
          position: absolute;
          width: 20px;
          height: 20px;
          background-image: url(@/assets/img/ui/small-star.png);
          background-position: center;
          background-repeat: no-repeat;
          left: calc(100% + 6px);
        }
      }

      &::before {
        position: absolute;
        left: 32px;
        content: counter(scoreboard-counter) ".";
      }

      &:nth-of-type(even) {
        background: none;
      }

      &:last-of-type {
        font-weight: bold;
        
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-image: url(@/assets/img/ui/scoreboard-dashed-line.png);
        }
      }
    }
  }
}

.Leaderboard--open {
  transform: translateY(-500px);
  z-index: 3;
  .Leaderboard__button {
    transform: scale(1);
    &_icon {
      > img {
        transition-duration: 0s;
        transform: rotate(0deg) !important;
        width: 22px;
      }
    }
  }

  &> .Leaderboard__button {
    .Leaderboard__button_icon {
      &::after {
        content: none;
      }
    }
  }

  .Leaderboard__window {
    opacity: 1;
  }
}
</style>