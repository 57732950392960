<template>
  <li>
    <div>
      <span>{{dialogue.user}}</span>
      <span>{{dialogue.date}}</span>
    </div>
    <p v-if="!isEditing" v-html="dialogue.title"></p>
    <textarea v-else v-model="editedText" ref="editTextarea"></textarea>

    <div>
      <div>
        <button @click.once="likePost" :class="{'hasreacted': hasReacted}" :key="hasPressedReact">
          <img src="@/assets/img/ui/dialogue/like.png">
          <span>
            {{dialogue.likes}}
          </span>
        </button>
        <button @click="focusInput">
          <img src="@/assets/img/ui/dialogue/comment.png">
          <span>
            {{dialogue.comments}}
          </span>
        </button>
      </div>

      <div v-if="!isInList && isMyDialogue">
        <button @click="deletePost">{{translations.delete}}</button>
        <button @click="editText">{{isEditing ? translations.save : translations.edit}}</button>
      </div>
    </div>
  </li>
</template>
<script>
export default {
  props: [
    "dialogue",
    "user",
    "translations",
    "isInList"
  ],
  data() {
    return {
      editedText: "",
      isEditing: false,
      hasPressedReact: 0
    }
  },
  mounted() {

  },
  methods: {
    async likePost() {
      if(!this.hasReacted) {
        await MicroServiceAPI.AddReactionToMessageBoardPost({
          MessageBoardId: this.messageBoardID,
          messageBoardParentPostId: this.dialogue.messageBoardPostId,
          messageBoardPostReactionId: "00000000-0000-0000-0000-000000000000",
          messageBoardPostReactionTypeId: "00000000-0000-0000-0000-000000000001"
        })
      } else {
        await MicroServiceAPI.RemoveMessageBoardPostReaction({
          MessageBoardId: this.messageBoardID,
          messageBoardPostReactionId: this.hasReacted.messageBoardPostReactionId
        })
      }

      this.$emit("likedPost")
      setTimeout(() => {
        this.hasPressedReact++;
      }, 100);
    },
    focusInput() {
      this.$emit("focusInput")
    },
    async deletePost() {
      await MicroServiceAPI.RemoveMessageBoardPost({
        MessageBoardId: this.messageBoardID,
        MessageBoardPostId: this.dialogue.messageBoardPostId,
        Reactions: [],
        Replies: []
      })

      this.$emit("deletedPost")
    },
    async editText() {
      if(this.isEditing === false) {
        this.editedText = this.dialogue.title;

        this.isEditing = true;
        setTimeout(() => {
          this.$refs.editTextarea.focus()
        }, 1);
      } else {
        this.isEditing = false
        await MicroServiceAPI.PostMessageBoardPost({
          MessageBoardId: this.translations.messageBoardID,
          MessageBoardPostId: this.dialogue.messageBoardPostId,
          MessageSubject: this.editedText,
          Reactions: [],
          Replies: []
        })

        this.dialogue.title = this.editedText

        this.$emit("likedPost")
      }
    }
  },
  computed: {
    hasReacted() {
      if (this.dialogue.reactions) {
        return this.dialogue.reactions.find(reaction => reaction.user === this.user);
      } else {
        return false;
      }
    },
    isMyDialogue() {
      return this.dialogue.user === this.user
    }
  }
};
</script>

<style lang="scss" scoped>
li {
  position: relative;
  cursor: pointer;
  padding: 14px 0 10px 0;
  margin-bottom: 0;
  height: 114px;
  list-style-type: none;

  &>div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;

    &>div {
      position: relative;
      width: 100%;
      display: flex;

      &:first-of-type {
        button {
          margin-right: 15px;
        }
      }

      &:nth-child(2) {
        flex-direction: row-reverse;
        button {
          margin-left: 10px;
          color: var(--dark-blue);
          font-size: 16px;
          margin-top: -4px;

          &:hover {
            text-shadow: 0px 0px 1px var(--dark-blue);
          }
        }
      }
    }

    span {
      display: flex;
      align-items: center;
      font-size: 13px;
      color: var(--main-color);
    }

    button {
      display: flex;
      align-items: center;
      font-size: 13px;
      color: var(--main-color);
      pointer-events: none;
      transition: ease 0.2s transform;

      &>img {
        margin-right: 6px;
      }

      &>span {
        min-width: 20px;
      }

      &:hover {
        &>img {
          transform: scale(1.08);
        }
      }

      &.hasreacted {
        text-shadow: 0px 0px 1px var(--main-color);
      }
    }

    &:last-of-type {
      position: relative;
      justify-content: flex-start;
      margin-bottom: 0;
      height: 20px;
    }
  }

  p{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: left;
    color: var(--dark-blue);
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 6px;
    height: 40px;
  }

  textarea {
    text-align: left;
    height: 200px;
    width: 100%;
    color: var(--dark-blue);
    font-size: 20px;
    line-height: 26px;
    cursor: text;
  }

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    height: 1px;
    background-color: rgb(#001965, .15);
    left: -32px; 
  }
}
</style>