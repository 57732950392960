<template>
    <div class="exhibitionScreens">
        <div class="exhibitionScreens__group" v-for="group in displayedScreensGroup" :key="group.id">
            <h5>{{group[0].title}}</h5>
            <ExhibitionScreensScreen v-for="(screen, i) in 6" :key="screen.position" :screen="group.screens[i] ? group.screens[i] : null" :class="'exhibitionScreens__group__screen--' + i"></ExhibitionScreensScreen>
        </div>
        <ExhibitionScreensNavigation :activeGroup="activeGroup" :pages="properties.screensGroup" @changePage="changePage"></ExhibitionScreensNavigation>
        <p class="exhibitionScreens__help-text" v-if="properties.helpText" v-html="properties.helpText"></p>
    </div>
</template>
<script>

export default {
    props: [
        "properties",
        "pageData"
    ],
    data() {
        return {
            activeGroup: 0
        }
    },
    created() {
        this.prepareScreens()
    },
    methods: {
        prepareScreens() {
            this.properties.screensGroup.forEach((group, i) => {
                group.id = `screenGroup__${i}`
                var numScreens = group.screens.length;
                let positions = this.getPositions(numScreens);
                
                group.screens.forEach((screen, index) => {
                    screen.position = positions[index];
                });
            });
        },
        getPositions(numScreens) {
            let positions = [];

            switch (numScreens) {
                case 0:
                    positions = [];
                    break;
                case 1:
                    positions = [1];
                    break;
                case 2:
                    positions = [1, 4];
                    break;
                case 3:
                    positions = [1, 3, 5];
                    break;
                case 4:
                    positions = [1, 2, 3, 4];
                    break;
                case 5:
                    positions = [1, 2, 3, 4, 5];
                    break;
                case 6:
                    positions = [0, 1, 2, 3, 4, 5];
                    break;
                default:
                    // For cases with more than 6 screens, distribute positions evenly
                    for (let i = 1; i <= numScreens; i++) {
                        positions.push(i);
                    }
            }
            
            return positions
        },
        changePage(i) {
            this.activeGroup = i
        }
    },
    computed: {
        displayedScreensGroup() {
            var screensGroup = []
            screensGroup.push(this.properties.screensGroup[this.activeGroup])
            var screens = []

            for(var i = 0; i < 6; i++) {
                if(this.properties.screensGroup[this.activeGroup].screens.find(screen => screen.position === i)) {
                    screens.push(this.properties.screensGroup[this.activeGroup].screens.find(screen => screen.position === i))
                } else {
                    screens.push(null)
                }
            }

            screensGroup.screens = screens

            return [screensGroup]
        }
    }
}
</script>
<style lang="scss">
.exhibitionScreens {
    position: absolute;
    width: 1154px;
    height: 479px;
    top: 284px;

    &__group {
        position: absolute;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        
        h5 {
            position: absolute;
            top: calc(100% + 6px);
            text-align: center;
            width: 100%;
            font-size: 22px;
            color: var(--dark-blue);
            font-weight: bold;
            user-select: none;
        }

        &>div {
            width: 369px;
            height: 226px;
            margin-bottom: 27px;
            opacity: 1;
        }
    }

    &__help-text {
        position: absolute;
        top: 619px;
        text-align: center;
        width: 100%;
        font-size: 16px;
        color: var(--dark-blue);
        user-select: none;
    }
}

.fadeScreens-enter-active,
.fadeScreens-leave-active {
    transition-property: opacity;
    transition-timing-function: var(--tk-easeinout);
}
.fadeScreens-enter-active {
    transition-duration: 0.3s;
}
.fadeScreens-leave-active {
    transition-duration: 0.15s;
}

.fadeScreens-enter-from,
.fadeScreens-leave-to {
  opacity: 0;
}
</style>