<template>
  <AppLoaderVue v-if="!ready"></AppLoaderVue>
  <AppContainer v-else></AppContainer>
</template>

<script>
import AppLoaderVue from './components/core/AppLoader.vue';
import AppContainer from './components/core/AppContainer.vue';

export default {
  components: {
    AppLoaderVue,
    AppContainer
  },
  created() {
    window.app.vm = this;
  },
  computed: {
    ready() {
      return this.$store.getters.ready;
    }
  },
};
</script>

<style lang="scss">
html,
body {
  height: 100vh;
  max-width: 100vw;
  scroll-behavior: smooth;
  background-color: var(--dark-blue);
  font-size: 18px;
  overflow: hidden;
  
  /* Disable zooming for all devices */
  touch-action: pan-x pan-y !important;
  user-scalable: none !important;
  -ms-touch-action: pan-x pan-y !important;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

img, button, a {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}


// Global Styling 
* {
  backface-visibility: hidden;
  touch-action: manipulation;

  // Scrollbar
  scrollbar-color: var(--main-color) var(--opactiy);
  scrollbar-width: thin;

  & *::-webkit-scrollbar {
    width: 8px;
  }

  & *::-webkit-scrollbar-track {
    background: none;
  }

  & *::-webkit-scrollbar-thumb {
    background: var(--main-color);
    height: 50%;
  }
}

p {
  display: block;
  margin-bottom: 1em;

  >a {
    display: inline;
    color: var(--dark-blue)
  }
}

strong, b {
  font-weight: 900;
}

em {
  font-style: italic;
}

ul, ol {
  list-style-position: outside;
  padding-left: 30px;
  margin-bottom: 24px;    

  li {
    margin-bottom: 4px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

ul {
  list-style-type: disc;
}

ol { 
  list-style-type: decimal;
}

button {
  cursor: pointer;

  &--disabled {
    cursor: default;
    pointer-events: none;
  }
}

.toggleButton {
  position: relative;
  display: flex;
  background-color: #eff7f7;
  border-radius: 25px;
  height: 36px;

  &::after  {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 50%;
    background-color: #fff;
    border-radius: inherit;
    transition: transform 0.2s cubic-bezier(0.455, 0.030, 0.515, 0.955);
    pointer-events: none;
  }
  
  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: var(--main-color);
    width: 50%;
    font-size: 13px;
    line-height: 15px;
    position: relative;
    height: 100%;
    z-index: 2;
    cursor: pointer;
  }

  &--active { 
    &::after {
        transform: translateX(100%);
    }
  }
}
</style>