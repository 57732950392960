<template>
    <div class="globe" :class="{ 'panned-left': showLocationInfo }">

        <div class="globe__loader">
            <div class="globe__loader-bg" :class="{ 'hidden': globeLoaded }">
                <img :src="getCDNpath('assets/img/ui/globe_loading.jpg')">
            </div>
            <div class="globe__loader-rings" :class="{ 'hidden': globeLoaded }"></div>
        </div>
        <iframe :class="{ 'active': globeLoaded }" @load="onIFrameLoaded" id="Orbit-modelviewer"
            src="realtimeOmv/omv-display.html"></iframe>

    </div>

    <article class="network_info" :class="{ 'panned-left': showLocationInfo }">
        <img class="network_info__background" :src="getCDNpath('assets/img/ui/splash-left.png')">
        <img class="network_info__photo" :src="getCDNpath('assets/img/ui/trio-teams-logo.jpg')" />
        <h3 class="network_info__title" v-html="this.properties.title"></h3>
        <div class="network_info__text" v-html="this.properties.text"></div>
        <a class="network_info__download" v-if="this.properties.downloadFile" :href="getMediaPath(this.properties.downloadFile.src)" download target="_blank">
            <div> <img src="@/assets/img/ui/download-small.png"> </div>
            <h6 v-html="this.properties.downloadTitle || 'Download'"></h6>
            <span v-html="this.properties.downloadText || 'To read more'"></span>
          </a>
        <div class="network_info__help" v-if="this.properties.infoText">
            <img src="@/assets/img/ui/rotateGlobe.png">
            <p v-html="this.properties.infoText"></p>
        </div>
    </article>

    <article class="location_info" :class="{ 'active': showLocationInfo }">
        <img class="location_info__background" :src="getCDNpath('assets/img/ui/splash-right.png')">
        <img v-if="photoTop" class="location_info__photo top" :src="photoTop" />
        <img v-if="photoRight" class="location_info__photo right" :src="photoRight" />

        <button class="button__close btnfx btnfx__hoverscale" @click="closeLocationInfo()">
            <img src="@/assets/img/ui/close_circle.png" />
        </button>

        <div class="location_info__title">{{ activeHotspotContent.title }}</div>
        <div class="location_info__subtitle" v-html="activeHotspotContent.subtitle"></div>
        <div class="location_info__text" v-html="this.properties.meetColleagesText"></div>

        <div class="location_info__columns">
            <div v-for="column in activeHotspotContent.columns" class="location_info__col" :key="column.title">
                <h5 v-if="column.title" v-html="column.title"></h5>
                <div v-html="column.text" class="location_info__col-text" :class="{'no-title': !column.title}"></div>
            </div>
        </div>

    </article>

</template>



<script>
import config from '@/config';
// import VideoPlayer from '../content-components/sub-components/VideoPlayer.vue'

export default {
    props: [
        "properties",
        "pageData"
    ],
    data() {
        return {
            // Host and Target
            httpHost: config.iframeHttp.host,
            httpTarget: config.iframeHttp.target,
            // httpHost: 'https://localhost:8080/',
            // httpTarget: 'https://localhost:8080/',

            // omv (globe) object
            orbitModelviewer: {},

            // is ovm ready?
            globeLoaded: false,

            // data object read by omv
            globeData: {},

            // toggling views
            showLocationInfo: false,

            // active data (location info) to show
            activeHotspotContent: {
                colleageVideos:[]
            },

            // event to fire on start up, if a hotspot is stored
            storedHotspotEvent: null
        }
    },
    created() {

    },
    mounted() {
        let orbitModelviewer = document.getElementById("Orbit-modelviewer");
        let src = app.api.Utils.getCDNpath('realtimeOmv/omv-display.html');
        orbitModelviewer.setAttribute("src", src);

        // any stored hotspot we need to show at start up?
        const storedGlobeHotspot = localStorage.getItem('storedGlobeHotspot');


        // build data object from Umbraco data to be readable by omv globe 
        this.globeData.modelUrl = "./Model/Globe4-dev-test.glb";
        this.globeData.custom = {};
        this.globeData.custom.hotspotLayers = [];

        // empty hotspot layer
        var emptyHotspotLayer = { name: "emptyLayer", hotspots: [] };
        this.globeData.custom.hotspotLayers.push(emptyHotspotLayer);

        // main hotspots layer
        var mainHotspotLayer = { name: "customLayer", hotspots: [] };
        this.globeData.custom.hotspotLayers.push(mainHotspotLayer);

        // interpretate hotspots
        this.properties.hotspots.forEach(hotspot => {

            var customHotspot = {};
            customHotspot.name = hotspot.locationID;
            customHotspot.text = hotspot.hotspotTitle;
            customHotspot.customStyle = { labelColor: '#ffffff', fontColor: '#005AD2' };
            customHotspot.localPosition = {
                x: parseFloat(hotspot.localPosition.xPosition),
                y: parseFloat(hotspot.localPosition.yPosition),
                z: parseFloat(hotspot.localPosition.zPosition)
            };

            var hotspotEventData = {};
            hotspotEventData.yaw = hotspot.globeRotation.yaw;
            hotspotEventData.pitch = hotspot.globeRotation.pitch;
            hotspotEventData.id = hotspot.hotspotName,
                hotspotEventData.content = {
                    title: hotspot.hotspotTitle,
                    subtitle: hotspot.hotspotSubtitle,
                    columns: hotspot.articleColumns,
                    colleageVideos: hotspot.colleageVideos,
                    photoTop: (hotspot.photoTop) ? hotspot.photoTop.src : "",
                    photoRight: (hotspot.photoRight) ? hotspot.photoRight.src : ""
                };

            customHotspot.event = { type: "Click", value: hotspotEventData };
            mainHotspotLayer.hotspots.push(customHotspot);

            // get event object of stored hotspot (to be fired when ovm is loaded)
            if (customHotspot.name == storedGlobeHotspot) {
                this.storedHotspotEvent = hotspotEventData;
            }


        });

    },

    methods: {

        onIFrameLoaded() {

            this.httpHost = window.location.origin;
            if (this.httpHost.includes("localhost")) {
                this.httpTarget = window.location.origin;
            }
            //console.log("HOST & Target: ", this.httpHost, this.httpTarget);

            //Handle postMessages from OMV Iframe
            window.addEventListener("message", (event) => {
                //console.log("Parent Recieve postMessage:", event);
                if (event.data.type && event.data.value) {
                    this.onGlobeRadio(event.data.type, event.data.value);
                }
            });

            this.orbitModelviewer = document.getElementById("Orbit-modelviewer");
            //console.log("OMV:", this.orbitModelviewer);

            //Rewrite radio and set the vue component globeData as static for transferable content.
            var radio = {
                http: this.httpHost
            };
            let staticGlobeData = JSON.parse(JSON.stringify([this.globeData]));

            //Initialize the OMV component with postMessage.
            this.orbitModelviewer.contentWindow.postMessage({
                function: "initialize", args: [radio, staticGlobeData, {
                    dynamicHotspotListing: false,
                    debugMode: false,
                    cameraClearcolor: "00000000",
                    // cameraMinZoomMultiplier: 1.5,
                    // cameraMaxZoomMultiplier: 1.5,
                    cameraMinZoomMultiplier: 1.15,
                    cameraMaxZoomMultiplier: 1.15,
                    hotspotScale: 1.7,
                    orbitMouseSensitivity: 0.1,
                    orbitIdleTimer: 30
                }]
            }, this.httpTarget);

            // fire stored hotspot event
            if (this.storedHotspotEvent) {
                this.onGlobeRadio("Click", this.storedHotspotEvent);
            }

        },

        onGlobeRadio(type, value) {

            if (value == "LoadingCompleted") {
                this.globeLoaded = true;
                this.orbitModelviewer.contentWindow.postMessage({ function: "activateHotspotsByLayerName", args: ["customLayer"] }, this.httpTarget);
                this.orbitModelviewer.contentWindow.postMessage({ function: "setActiveCameraPosition", args: [-79, -20, null, null, null, 300, null] }, this.httpTarget);

                // reload stored hotspot for correct yaw and pitch
                if (this.storedHotspotEvent) {
                    this.onGlobeRadio("Click", this.storedHotspotEvent);
                }

                return;
            }

            if (type == "Click") {
                var yaw = value.yaw ? value.yaw : 0;
                var pitch = value.pitch ? value.pitch : 0;
                this.showLocationInfo = true;
                if (this.globeLoaded) {
                    this.orbitModelviewer.contentWindow.postMessage({ function: "activateHotspotsByLayerName", args: ["emptyLayer"] }, this.httpTarget);
                    this.orbitModelviewer.contentWindow.postMessage({ function: "setActiveCameraPosition", args: [yaw, pitch, null, null, null, 300, null] }, this.httpTarget);
                }

                // html dependant var
                this.activeHotspotContent = value.content;

                console.log(this.activeHotspotContent)

                // test saved hotspot id
                //localStorage.setItem('storedGlobeHotspot', value.id);
                return;
            }

        },

        closeLocationInfo() {
            // show hotspots on globe
            this.orbitModelviewer.contentWindow.postMessage({ function: "activateHotspotsByLayerName", args: ["customLayer"] }, this.httpTarget);
            // back to default rotation
            this.orbitModelviewer.contentWindow.postMessage({ function: "setActiveCameraPosition", args: [-32, -29, null, null, null, 300, null] }, this.httpTarget);
            // close location info view
            this.showLocationInfo = false;
            // forget stored hotspot event
            this.storedHotspotEvent = null;

            var videoElements = document.querySelectorAll(".videoplayer video");

            for (var i = 0; i < videoElements.length; i++) {
                console.log(videoElements[i])
                videoElements[i].pause();
            }
        },
        getMediaPath(src) {
        return app.api.Utils.getMediaPath(src);
        },
        getCDNpath(asset) {
            return app.api.Utils.getCDNpath(asset)
        },
    },

    computed: {

        photoTop() {
            if (this.activeHotspotContent.photoTop)
                return app.api.Utils.getMediaPath(this.activeHotspotContent.photoTop);
        },
        photoRight() {
            if (this.activeHotspotContent.photoRight)
                return app.api.Utils.getMediaPath(this.activeHotspotContent.photoRight);
        }

    },

    unmounted() {
        // remove stored location
        localStorage.removeItem('storedGlobeHotspot');
    }


};
</script>



<style lang="scss">
.globe {
    position: absolute;
    left: 990px;
    top: 80px;
    width: 1200px;
    height: 1000px;
    transition: transform, 0.5s cubic-bezier(.75, 0, .25, 1);

    iframe {

        width: 100%;
        height: 100%;
        border: none;
        opacity: 0;
        transition: opacity, 1s cubic-bezier(.75, 0, .25, 1);
        -khtml-user-select: none;
        -o-user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        user-select: none;

        cursor: move;

        &.active {
            opacity: 1
        }
    }


    .globe__loader {

        display: flex;
        justify-content: center;
        align-items: center;
        height: 1020px;
        width: 1200px;
        position: absolute;
        pointer-events: none;

        .globe__loader-bg {
            position: absolute;
            background-size: contain;
            width: 100%;
            height: 78%;
            margin-bottom: 30px;
            transition: opacity, 1s cubic-bezier(.75, 0, .25, 1);
            opacity: 0.5;

            &.hidden {
                opacity: 0;
            }

            &>img {
                pointer-events: none;
                height: 100%;
            }
        }

        .globe__loader-rings {
            position: absolute;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            border: 2px solid #fff;
            border-left: 2px solid transparent;
            animation: rotation 1s infinite linear;

            &.hidden {
                opacity: 0;
                animation: none;
            }

            @keyframes rotation {
                from {
                    transform: rotate(0deg);
                }

                to {
                    transform: rotate(359deg);
                }
            }
        }


    }

    &.panned-left {
        transform: translateX(-1550px);
    }

    &.panned-down {
        transition: transform, 1s cubic-bezier(.50, 0, .50, 1);
        transform: translateY(900px);
    }


}


.network_info {
    color: var(--light-grey);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 200px;
    width: 392px;
    height: 100%;
    text-align: left;
    transition: transform, 0.5s cubic-bezier(.75, 0, .25, 1);
    z-index: -1;

    &__background {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -240px;
        z-index: -1;
    }

    &__photo {
        position: absolute;
        object-fit: cover;
        width: 276px;
        height: 276px;
        left: 689px;
        top: 613px;
        border-radius: 250px;
    }

    &.panned-left {
        transform: translateX(-1700px);
    }

    &.panned-down {
        transition: transform, 1s cubic-bezier(.50, 0, .50, 1);
        transform: translateY(900px);
    }

    .network_info__title {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 14px;
    }

    .network_info__text > p:last-child { margin-bottom: 0; }

    .network_info__download {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        height: 50px;
        padding-left: 66px;
        margin-top: 40px;

        > div {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          left: 0;
          top: 0;
          height: 50px;
          width: 50px;
          min-width: 50px;
          background-color: var(--main-color);
          background-clip: padding-box;
          border: 4px solid var(--main-color-transparent);
          border-radius: 50%;
          color: var(--main-color);
          transition: all 0.3s var(--tk-easeinout);

          img {
            width: 20px;
          }
        }

        h6 {
          font-weight: bold;
          font-size: 20px;
          line-height: 1.2;
        }
        span {
          font-size: 15px;
        }

        &:hover {
          & div {
            transform: scale(1.1);
          }
        }
      }

    .network_info__help {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: start;
        gap: 22px;
        width: 392px;
        position: absolute;
        top: 120px;
        left: 562px;

        p { width: 250px; }
    }
}

.invite {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    right: -83px;
    transition: transform, 0.5s cubic-bezier(.70, 0, .30, 1);

    width: 300px;
    height: 275px;
    pointer-events: all;
    padding-bottom: 20px;

    .invite__hotspot {
        pointer-events: all;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        width: 48px;
        background-clip: padding-box;
        background-image: url(@/assets/img/ui/arrow_dropdown.png);
        background-size: 22px;
        background-position: 50% 54%;
        background-repeat: no-repeat;
        background-origin: content-box;
        border: 4px solid var(--main-color-transparent);
        border-radius: 50%;
        font-size: 30px;
        font-weight: 900;
        transform: rotate(-90deg);
        background-color: #fff;

        transition: all 0.3s var(--tk-easeinout);

    }


    &.move-out {
        transform: translate(300px, 50px);
    }

    &>img {
        position: absolute;
        z-index: -1;
    }
}

.location_info {
    position: fixed;
    width: 1340px;
    height: 540px;
    left: 600px;
    top: 460px;
    text-align: left;
    color: var(--light-grey);
    pointer-events: none !important;
    opacity: 0;
    transition: opacity, 0.3s;



    &.active {
        transition: opacity, 1s 0.3s;
        pointer-events: all !important;
        opacity: 1;
    }


    .location_info__background {
        position: absolute;
        top: -477px;
        left: 202px;
    }

    .location_info__photo  {
        object-fit: cover;
        border-radius: 1000px;
        position: absolute;

        &.top {
            width: 724px;
            height: 724px;
            top: -858px;
            left: 222px;
            padding-top: 360px;
        }

        &.right {
            width: 278px;
            height: 278px;
            top: -202px;
            left: 951px;
        }
    }


    .location_info__images {
        position: fixed;
        top: 0px;
        left: 353px;
        width: 1920px;
        height: 1080px;
        overflow: hidden;

        .location_info__arrow {
            position: absolute;
            top: 187px;
            left: 0px;
            opacity: 0;
            transition: opacity 1s;

            &.active {
                opacity: 1;
            }
        }


        .location_info__videos {

            position: absolute;
            border: 15px solid #fff;
            background-color: #fff;
            width: 280px;
            min-height: 100px;
            top: 80px;
            left: 1132px;
            transform: rotate(-4deg) translateY(-300px);
            transition: transform 1s 0.3s cubic-bezier(.70, 0, .30, 1);
            -webkit-box-shadow: 10px 19px 15px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 10px 19px 15px 0px rgba(0, 0, 0, 0.2);


            &>img {
                width: 100%;
                margin: 50px 0 25px;
            }

            .videoplayer {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                margin-top: 10px;

                video {
                    // fix rough edges
                    outline: 3px solid #fff !important;
                    outline-offset: -1px;
                    display: block;
                }

                .play-button {
                    left: 80%;
                }


            }

            &.active {
                transform: rotate(-4deg) translateY(0px);
            }
        }

    }



    .button__close {
        position: absolute;
        left: -70px;
        top: -7px;
    }

    .location_info__title {
        font-size: 22px;
        text-transform: uppercase;
        padding-bottom: 60px;
    }

    .location_info__subtitle {

        font-size: 18px;
        font-weight: 400;
        margin: 1em 0;
        text-align: left;
        width: 651px;
    }

    .location_info__text {
        // position: absolute;
        font-size: 18px;
        font-weight: 400;
        top: 75px;
        text-align: left;
        width: 651px;
    }

    .location_info__columns {
        display: flex;
        gap: 100px;
        position: absolute;


        .location_info__col {
            text-align: left;
            width: 400px;
            height: 170px;


            h5 {
                font-size: 30px;
                font-weight: 700;
                margin-bottom: 0.6em;
            }

            .location_info__col-text {
                font-size: 18px;
                font-weight: 400;

                &.no-title { margin-top: 61px; }
                p:last-child { margin-bottom: 0; }
            }
        }
    }
}
</style>