
<template>
    <transition name="HotspotEntrance">
        <router-link class="Hotspot defaultHotspot btnfx " v-show="ready" :to="hotspot.route"
            :class="[getHotspotMutatorClasses, getHotspotContentClass, getHotspotTypeClass]"
            :style="[getPosition]" @click="hotspotPressed">
            <div class="defaultHotspot__button btnfx__clicklight btnfx__hoverhalo" :style="getAlternateHotspotIcon">
            </div>
            <div class="defaultHotspot__label" :class="{ show: rollout }">
                <span v-html="getHotspotTitle"></span>
            </div>
        </router-link>
    </transition>
</template>

<script>

export default {
    props: ['hotspot'],
    data() {
        return {
            ready: false,
            rollout: false
        }
    },
    mounted() {
        this.renderHotspotCascade();
    },
    methods: {
        renderHotspotCascade() {
            if(this.getHotspotTypeClass == "defaultHotspot--Blue") {
                this.ready = true;
                this.rollout = true
                return
            }


            let delay = 0;
            delay = (this.hotspot.index * 200) + 400;

            setTimeout(() => {
                this.ready = true;
            }, delay);

            setTimeout(() => {
                this.rollout = true;
            }, delay * 2);
        },
        hotspotPressed() {
            this.$emit('hotspotPressed', this.hotspot)
        }
    },
    computed: {

        getHotspotTitle() {
            return this.hotspot.data.alternateTitle && this.hotspot.data.alternateTitle !== '' ? this.hotspot.data.alternateTitle : this.hotspot.data.title;
        },
        getHotspotTypeClass() {
            let hotspotPrefix = "defaultHotspot--"

            switch (this.hotspot.data.hotspotType) {
                case "Blue":
                    return `${hotspotPrefix}Blue`

                case "Coming Soon":
                    return `${hotspotPrefix}ComingSoon`

                case "No Label":
                    return `${hotspotPrefix}NoLabel`

                default:
                    return
            }
        },
        getPosition() {
            return {
                left: this.hotspot.data.positionData.xPosition + 'px',
                top: this.hotspot.data.positionData.yPosition + 'px'
            }
        },
        getHotspotMutatorClasses() {
            return {
                'defaultHotspot--flipped': this.hotspot.data.flipped,
                'defaultHotspot--hidden': this.hotspot.data.hidden,
                'defaultHotspot--customicon': this.hotspot.data.alternateHotspotIcon !== null
            }
        },
        getHotspotContentClass() {
            if (this.hotspot.componentData) {
                return `defaultHotspot--${this.hotspot.componentData.type}`
            } else {
                return
            }
        },
        getAlternateHotspotIcon() {
            if (this.hotspot.data.alternateHotspotIcon !== null) {
                return {
                    'background-image': `url(${app.api.Utils.getMediaPath(this.hotspot.data.alternateHotspotIcon.src)})`
                };
            }
        }
    }
}
</script>


<style lang="scss">
$hotspot-height: 48px;
$hotspot-border-radius: calc($hotspot-height/2);


.defaultHotspot {
    display: flex;

    .defaultHotspot__button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: $hotspot-height;
        width: $hotspot-height;
        min-width: $hotspot-height;
        background-color: var(--main-color);
        background-clip: padding-box;
        background-image: url(@/assets/img/ui/plus.png);
        background-size: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-origin: content-box;
        border: 4px solid var(--main-color-transparent);
        border-radius: 50%;
        color: var(--main-color);
        transition-property: all;
        transition-duration: 0.3s;
        transition-timing-function: var(--tk-easeinout);
    }

    .defaultHotspot__label {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: var(--main-color);
        font-size: 18px;
        height: $hotspot-height;
        background: rgba(255, 255, 255, 0.9);
        margin-left: - $hotspot-height;
        z-index: -1;
        border-radius: $hotspot-border-radius;
        padding: 0 24px 0 calc($hotspot-height + 15px);
        transition: all .4s var(--tk-easeinout);
        transform-origin: center left;
        z-index: -2;
        transform: scaleX(0.2) translateX(50px);
        opacity: 0;

        span {
            white-space: nowrap;
        }

        b,

        strong {
            font-weight: 900;
        }

        &.show {
            transform: scaleX(1) translateX(0);
            opacity: 1;
        }
    }


    &--flipped {
        flex-direction: row-reverse;

        .defaultHotspot__label {
            margin-left: auto;
            margin-right: - $hotspot-height;
            padding: 0 calc($hotspot-height + 15px) 0 24px;
            transform-origin: center right;
            transform: scaleX(0.2) translateX(-50px);
        }
    }

    &--hidden {
        display: none;
    }

    &--customicon {
        .defaultHotspot__button {
            background-size: 26px;
            transform: rotate(0deg) !important;
        }
    }

    &:hover {
        .defaultHotspot__button {
            transform: rotate(90deg);
        }

        .defaultHotspot__label {
            background: rgba(255, 255, 255, 1);
            color: var(--main-color);
        }
    }

    &:active {
        .defaultHotspot__label {
            transition: none;
        }
    }

    // Styles for specifc components that hotspot can open
    &--videoLibrary,
    &--fullscreenVideo,
    &--fullscreenIframePlayer {
        .defaultHotspot__button {
            background-size: 20px;
            background-image: url(@/assets/img/ui/play.png);
            background-position: 10px;
            transform: rotate(0deg) !important;
        }
    }

    // Styles for specific hotspot types defined by getHotspotTypeClass()
    &--NoLabel {
        .defaultHotspot__label {
            display: none;
        }
    }

    &--Blue {
        transition-duration: 0s !important;
        
        .defaultHotspot__button {
            display: none;
        }

        .defaultHotspot__label {
            background: var(--main-color);
            color: #fff;
            padding: 0 24px 0 24px;
            min-width: 292px;
            justify-content: center;
            height: 53px;
            border-radius: 1000px;
        }
    }
}

/* entrance animation */
.HotspotEntrance-enter-active {
    transition: all 0.5s var(--tk-easeinout);
}

.HotspotEntrance-leave-active {
    transition: all 0.3s var(--tk-easeinout);
}

.HotspotEntrance-enter-from {
    transform: translateY(150px);
    opacity: 0;
}

.HotspotEntrance-leave-to {
    opacity: 0;
}
</style>




