<template>
  <div id="AspectContainer" ref="appFrame">
    <slot></slot>
  </div>
</template>

<script>
import lodash from 'lodash';

export default {
  data: () => {
    return {
      scale: 1,
      useRatio2BrowserUpscale: false,
      followBgWidth: true,
      maximizedSizeAndOffset: {},
      throttledUpdateFrameSize: null
    };
  },
  mounted() {
    this.updateFrameSize();
    
    this.throttledUpdateFrameSize = lodash.throttle(this.updateFrameSize, 250);
    window.addEventListener("resize", this.throttledUpdateFrameSize);
    window.addEventListener("backgroundLoaded", this.calcMaximizedSizeAndOffset);
  },
  methods: {
    updateFrameSize() {
      // NOTE: Scaling handler. uses css transform: scale on the #app id to scale the entire scene.
      // The math to determine how much scale to apply to the scene.
      // It will make the app as big as it can be while maintaining its aspect ratio. The app will be centered.
      // With a purpose of displaying a fixed 16:9 app.


      var windowResolution = {
        width: window.innerWidth,
        height: window.innerHeight,
      };

      var appResolution = {
        width: this.$refs.appFrame.offsetWidth,
        height: this.$refs.appFrame.offsetHeight,
      };


      var windowAspectRatio = windowResolution.width / windowResolution.height;
      var appAspectRatio = appResolution.width / appResolution.height;


      // NOTE: Using 2:1 aspect ratio backgrounds to hide side borders may not be sufficient in many browser viewports
      // To avoid rendering out wider (= heavier) videos or uncommon video aspects ratios,
      // we then add a small upscale to compensate for the last missing pixels, 
      // within the ratio range of 2 to 2.1 (gives a minimum viewport height of 915px on a 1920px wide screen, and cuts maximum 45px of the height)
      var browserViewportUpscale = 1;
      if (this.useRatio2BrowserUpscale && windowAspectRatio >= 2 && windowAspectRatio < 2.1) {
        browserViewportUpscale = windowAspectRatio / 1.999; // instead of 2 to avoid a remaining left pixel due to round up
      }

      var windowAspectRatioIsWiderThanApp = windowAspectRatio > appAspectRatio;

      if (windowAspectRatioIsWiderThanApp) {
        // NOTE: Window is WIDER than app
        // NOTE: Resize app based on height
        var heightDifference = windowResolution.height / appResolution.height * browserViewportUpscale;
        this.$refs.appFrame.style.transform = "scale(" + heightDifference + ") translate(-50%, -50%)";
        this.scale = heightDifference;
      } else {
        // NOTE: Window is TALLER than app
        // NOTE: Resize app based on width
        var widthDifference = windowResolution.width / appResolution.width;
        this.$refs.appFrame.style.transform = "scale(" + widthDifference + ") translate(-50%, -50%)";
        this.scale = widthDifference;
      }

      // store scale and resolution
      this.$store.commit('setScaleValue', this.scale);
      this.$store.commit('setAppResolutionValue', appResolution);
      window.dispatchEvent(new Event('scaleChanged'));

      this.calcMaximizedSizeAndOffset();
    },

    calcMaximizedSizeAndOffset() {
      // NOTE: This calculates the counter -width/height and -top/left props for the original app size (mostly 1920x1080) 
      // to maximize against any wider background/window-width and upscale compensations.
      // This will make any layer resposive to the wider size of a backgrund with the screensize as maximum. 
      // The props are saved in store.js and used on the IULayer and the Content Components Layer

      if (this.followBgWidth || this.useRatio2BrowserUpscale) {

        // working size vars
        var winResolution = { width: window.innerWidth, height: window.innerHeight };
        var appResolution = this.$store.getters.getAppResolutionValue;
        var globalScale = this.$store.getters.getScaleValue;

        // follow bg width (with screen width as maximum)
        var backgroundWidth = this.$store.getters.getBackgroundWidth > 0 ? this.$store.getters.getBackgroundWidth : appResolution.width;
        var scaledWindowWidth = winResolution.width / globalScale;
        var minDynamicWidth = Math.min(backgroundWidth, scaledWindowWidth);

        // compensates for viewport upscales (when window wider than app)
        var isWindowsWiderThanApp = (winResolution.width / winResolution.height) > (appResolution.width / appResolution.height);
        var scaledWindowHeight = window.innerHeight / globalScale;
        var upScaleOffsetYCompensation = (appResolution.height - scaledWindowHeight) / 2;

        // set size style
        this.maximizedSizeAndOffset = {
          width: (this.followBgWidth) ? minDynamicWidth + "px" : "",
          left: (this.followBgWidth) ? -(minDynamicWidth - appResolution.width) / 2 + "px" : "",
          height: (isWindowsWiderThanApp) ? scaledWindowHeight + "px" : "",
          top: (isWindowsWiderThanApp) ? upScaleOffsetYCompensation + "px" : "0px"
        }



        // save in store (used by UILayer and Page_ContentComponents)
        this.$store.commit('setMaximizedSizeAndOffsetValue', this.maximizedSizeAndOffset);
      }
    }
  },
};
</script>

<style lang="scss">
#AspectContainer {
  position: absolute;
  width: 1920px;
  height: 1080px;
  touch-action: none; // avoid scrolling on touch devices
  backface-visibility: hidden;
  margin: 0;
  will-change: transform;

  left: 50%;
  top: 50%;
  transform-origin: top left;
  // overflow: hidden;
}
</style>