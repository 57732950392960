<template>
    <transition name="toggleContentComponent">
        <article class="videoLibrary" v-if="ready">
            <!-- TOPBAR -->
            <nav class="videoLibrary__topbar">
                <button class="button__close btnfx btnfx__hoverscale" @click="closeComponent">
                    <img src="@/assets/img/ui/close_circle.png" />
                </button>
                <h3 v-html="this.properties.title"></h3>
            </nav>

            <!-- CONTENT -->
            <div class="videoLibrary__center">

                <div class="video-grid" :class="{ four : properties.videos.length > 9 }">

                    <div class="video-item" :class="{disabled:video.disabled}" v-for="video in properties.videos" @click="openLink(video.video)">

                        <img v-if="video.thumbnail" :src="getImagePath(video.thumbnail.src)">

                        <div class="video-text">
                            <h4 v-if="video.title" v-html="video.title"></h4>
                            <p class="video-subheading" v-if="video.subheading" v-html="video.subheading"></p>
                            <div class="video-description" v-if="video.description" v-html="video.description"></div>
                        </div>

                    </div>

                </div>

            </div>

            <div class="videoLibrary__background">
                <img :src="getCDNpath('assets/img/ui/video-bg.jpg')">
            </div>

        </article>
    </transition>
</template>
<script>
export default {
    props: ["properties"],
    data() {
        return {
            ready: false
        }
    },
    mounted() {


        setTimeout(() => {
            this.ready = true;
        }, 1);


    },
    computed: {


    },
    methods: {
        closeComponent() {
            this.ready = false;
            app.api.PageManager.closeAllContentComponents();
        },


        getCDNpath(asset) {
            return app.api.Utils.getCDNpath(asset)
        },

        getImagePath(src) {
            return app.api.Utils.getMediaPath(src);
        },

        openVideo(video) {

            window.open(this.fixUrl(video.videoUrl), '_blank');
        },
        fixUrl(url) {
            // Check if the URL starts with 'http://' or 'https://'
            if (!/^https?:\/\//i.test(url)) {
                // If not, add 'http://' to the beginning of the URL
                url = 'http://' + url;
            }
            return url;
        },
        

    openLink(id) {
        if (id) 
            app.api.PageManager.openContentComponentFromID(id, false);
    },


    }
}
</script>
 
<style lang="scss">
.videoLibrary {
    
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 927px;
    bottom: 0;
    color: #fff;
    
    &__topbar {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 110px;
        min-height: 110px;
        width: 100%;
        padding: 0 108px;

        .button__close {
            left: 40px;
            margin-right: 30px;
        }

        h3 {
            font-size: 22px;
            text-transform: uppercase;
            margin-right: auto;
            margin-bottom: 6px; // better centering fix
        }
    }

    
    &__center {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 0 108px;

        &>.video-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(3, auto);
            grid-auto-flow: column;
            grid-column-gap: 40px;

            &.four {
                grid-template-rows: repeat(4, auto);
            }


            &>.video-item {
                display: grid;
                grid-template-columns: auto 1fr;
                gap: 20px;
                margin-bottom: 40px;

                &.disabled {
                    pointer-events: none;
                    opacity: 0.5;
                }

                img {
                    width: 250px;
                    height: 141px;
                    object-fit: cover;
                    outline: 0px solid var(--dark-blue);
                    outline-offset: 0px;
                    transition: all 0.1s;
                }

                .video-text {
                    text-align: left;
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;

                    h4 {
                        font-size: 18px;
                        font-weight: bold;
                    }

                    .video-subheading {
                        color: var(--light-pink);
                        font-size: 18px;
                        text-transform: uppercase;
                        margin-bottom: 12px;
                    }

                    .video-description {
                        font-size: 16px;
                        margin-top: 12px;
                        p:last-child { margin: 0; }
                    }
                }

                &:hover {
                    cursor: pointer;

                    img {
                        outline: 8px solid var(--main-color);
                        outline-offset: -8px;
                    }

                }
            }
        }
    }

    // background
    &__background {
        position: absolute;
        inset: 0;
        z-index: -1;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }


    .button__close {
        top: 40px;
        left: 40px;
    }

}
</style>