<template>
  <article class="backgroundMedia" ref="media" :class="getModifierClasses" :style="[getPosition, getSize, getMatrix3D]" @click="navigateTo">
    <span v-if="properties.matrix3dDebugMode" v-html="matrix3dDebugText"></span>
    <img v-else-if="mediaType === 'image'" :src="getSrc">
    <video v-else-if="mediaType === 'video'" autoplay muted loop playsinline webkit-playsinline disableRemotePlayback disablePictureInPicture>
      <source :src="getSrc">
    </video>
  </article>
</template>

<script>
const numeric = require('numeric');

export default {
  data() {
    return {
      matrix3dDebugText: "MATRIX 3D DEBUG MODE",
      matrixAnchorClass: "backgroundMedia__matrix3dAnchor"
    }
  },
  props: [
    "properties",
    "pageData"
  ],
  mounted() {
    // SCALE OF APP MUST BE EXACTLY 1 WHEN THIS IS LOADED
    if(this.properties.matrix3dDebugMode === true)
      setTimeout(() => {
        // simple timeout to make sure the background component layer has received its full width before calculating
        this.makeTransformable()
      }, 1000);
  },
  methods: {
    navigateTo() {
      if(this.properties.matrix3dDebugMode) {
        this.copyMatrix3dStyle();
        return
      }
      

      if(this.hasSceneLink) {
        return app.api.PageManager.navigateToID(this.properties.link)
      } else if (this.hasContentLink) {
        return app.api.PageManager.openContentComponentFromID(this.properties.content);
      }
    },

    // MATRIX 3d
    async makeTransformable(callback) {      
      var t = this
      
      var controlPoints = ['left top', 'left bottom', 'right top', 'right bottom'].map(function(position) {
        var div = t.createAnchor();
  
        var positionData = position.split(" ");
        var cornerX = positionData[0];
        var cornerY = positionData[1];
  
        var rect = t.$refs.media.getBoundingClientRect();
        var offsetX = cornerX === 'left' ? rect.left : rect.right;
        var offsetY = cornerY === 'top' ? rect.top : rect.bottom;
  
        div.style.left = offsetX - 10 + 'px';
        div.style.top = offsetY - 10 + 'px';
  
        return div;
      });  

      
      var originalPos = controlPoints.map(function(p) {
        return [p.offsetLeft, p.offsetTop];
      });

      controlPoints.forEach(function(p) {
        var startX, startY;
        p.addEventListener('mousedown', function(event) {
          startX = event.clientX - p.offsetLeft;
          startY = event.clientY - p.offsetTop;
          document.addEventListener('mousemove', onMouseMove);
          document.addEventListener('mouseup', onMouseUp);
        });
  
        function onMouseMove(event) {
          p.style.left = (event.clientX - startX) + 'px';
          p.style.top = (event.clientY - startY) + 'px';

          var targetPos = [];
          controlPoints.forEach(function(p) {
            var rect = p.getBoundingClientRect();
            targetPos.push([rect.left, rect.top]);
          });

          t.applyTransform(t.$refs.media, originalPos, targetPos, callback);
        }
  
        function onMouseUp() {
          document.removeEventListener('mousemove', onMouseMove);
          document.removeEventListener('mouseup', onMouseUp);
        }
      });
    },
    createAnchor() {
      let div = document.createElement('div');
      div.className = this.matrixAnchorClass
      div.style.height = '20px';
      div.style.width = '20px';
      div.style.background = 'rgba(255, 255, 255, 0.5)';
      div.style.borderRadius = '20px';
      div.style.cursor = 'move';
      div.style.position = 'absolute';
      div.style.zIndex = 100000;
      
      // Create the cross pseudo-elements
      let crossBefore = document.createElement('div');
      crossBefore.className = 'cross-before';
      crossBefore.style.position = 'absolute';
      crossBefore.style.width = '2px'; // Adjust thickness of cross
      crossBefore.style.height = '100%';
      crossBefore.style.backgroundColor = 'rgba(0, 0, 0, 0.5)'; // Adjust opacity here
      crossBefore.style.left = '50%';
      crossBefore.style.top = '0';
      crossBefore.style.transform = 'translateX(-50%)';
      crossBefore.style.zIndex = 100001;

      let crossAfter = document.createElement('div');
      crossAfter.className = 'cross-after';
      crossAfter.style.position = 'absolute';
      crossAfter.style.width = '100%';
      crossAfter.style.height = '2px'; // Adjust thickness of cross
      crossAfter.style.backgroundColor = 'rgba(0, 0, 0, 0.5)'; // Adjust opacity here
      crossAfter.style.top = '50%';
      crossAfter.style.left = '0';
      crossAfter.style.transform = 'translateY(-50%)';
      crossAfter.style.zIndex = 100001;

      // Append the cross pseudo-elements to the div
      div.appendChild(crossBefore);
      div.appendChild(crossAfter);

      document.body.appendChild(div);

      return div
    },
    applyTransform(element, originalPos, targetPos, callback){
      var t = this
      var H, from, i, j, p, to;
      // All offsets were calculated relative to the document
      // Make them relative to (0, 0) of the element instead
      from = (function() {
        var k, len, results;
        results = [];
        for (k = 0, len = originalPos.length; k < len; k++) {
          p = originalPos[k];
          results.push({
            x: p[0] - originalPos[0][0],
            y: p[1] - originalPos[0][1]
          });
        }
        return results;
      })();
      to = (function() {
        var k, len, results;
        results = [];
        for (k = 0, len = targetPos.length; k < len; k++) {
          p = targetPos[k];
          results.push({
            x: p[0] - originalPos[0][0],
            y: p[1] - originalPos[0][1]
          });
        }
        return results;
      })();
      // Solve for the transform
      H = t.getTransform(from, to);
      
      element.style.transform = `matrix3d(${((function() {
        var k, results;
        results = [];
        for (i = k = 0; k < 4; i = ++k) {
          results.push((function() {
            var l, results1;
            results1 = [];
            for (j = l = 0; l < 4; j = ++l) {
              results1.push(H[j][i].toFixed(20));
            }
            return results1;
          })());
        }
        return results;
      })()).join(',')})`
      element.style.transformOrigin = '0 0';

      return typeof callback === "function" ? callback(element, H) : void 0;
    },
    getTransform(from, to) {
      var A, H, b, h, i, k, k_i, l, lhs, m, ref, rhs;
      console.assert((from.length === (ref = to.length) && ref === 4));
      A = []; // 8x8
      for (i = k = 0; k < 4; i = ++k) {
        A.push([from[i].x, from[i].y, 1, 0, 0, 0, -from[i].x * to[i].x, -from[i].y * to[i].x]);
        A.push([0, 0, 0, from[i].x, from[i].y, 1, -from[i].x * to[i].y, -from[i].y * to[i].y]);
      }
      b = []; // 8x1
      for (i = l = 0; l < 4; i = ++l) {
        b.push(to[i].x);
        b.push(to[i].y);
      }
      // Solve A * h = b for h
      h = numeric.solve(A, b);
      H = [[h[0], h[1], 0, h[2]], [h[3], h[4], 0, h[5]], [0, 0, 1, 0], [h[6], h[7], 0, 1]];
      // Sanity check that H actually maps `from` to `to`
      for (i = m = 0; m < 4; i = ++m) {
        lhs = numeric.dot(H, [from[i].x, from[i].y, 0, 1]);
        k_i = lhs[3];
        rhs = numeric.dot(k_i, [to[i].x, to[i].y, 0, 1]);
        console.assert(numeric.norm2(numeric.sub(lhs, rhs)) < 1e-9, "Not equal:", lhs, rhs);
      }
      return H;
    },
    copyMatrix3dStyle(){
      const element = this.$refs.media;
      const styles = window.getComputedStyle(element);
      
      console.log(styles.getPropertyValue('transform'))
      const transformValue = styles.getPropertyValue('transform');

      this.matrix3dDebugText = `TRANSFORM:MATRIX3D COPIED TO CLIPBOARD<br>${transformValue  }`

      // Copy the transform value to the clipboard
      navigator.clipboard.writeText(transformValue).then(() => {
        console.log('Transform value copied to clipboard:', transformValue);
      }).catch(err => {
        console.error('Failed to copy transform value:', err);
      });
    },
    remove3dAnchors() {
      document.querySelectorAll(`.${this.matrixAnchorClass}`).forEach(e => e.remove());
    }
  },
  beforeUnmount() {
    window.removeEventListener("backgroundLoaded", this.makeTransformable());
    this.remove3dAnchors()
  },
  computed: {
    hasSceneLink() {
      return this.properties.link !== null
    },
    hasContentLink () {
      return this.properties.content !== null
    },
    getSrc() {
      if(this.properties.src)
        return app.api.Utils.getMediaPath(this.properties.src.src)
    },
    getPosition() {
      if(this.getMatrix3D === "" && this.properties.matrix3dDebugMode === false)
        return {
            left: this.properties.positionData.xPosition + 'px',
            top: this.properties.positionData.yPosition + 'px'
        }
    },
    getSize() {
        return {
            width: this.properties.componentSize.width !== '' ? this.properties.componentSize.width : 'auto',
            height: this.properties.componentSize.height !== '' ? this.properties.componentSize.height : 'auto' 
        }
    },
    getModifierClasses() {
      return {
        'backgroundMedia--link': this.hasSceneLink || this.hasContentLink,
        'backgroundMedia--hasMatrix3d': this.hasMatrix3d,
        'backgroundMedia--matrix3dDebugMode': this.properties.matrix3dDebugMode
      }
    },
    mediaType() {
      if(this.properties.src)
        return app.api.Utils.getMediaType(this.properties.src.src)
    },
    hasMatrix3d() {
      return this.properties.matrix3d !== ''
    },
    getMatrix3D() {
      //Border is added for anit-aliasing on videos
      if(this.properties.matrix3dDebugMode === true) {
        return ''
      } else {
        return this.hasMatrix3d ? `transform: ${this.properties.matrix3d}` : ''
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.backgroundMedia {
  position: absolute;
  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;

  &>*{ 
    pointer-events: none;
    max-width: 100%;
    max-height: 100%;
  }

  &--link {
    cursor: pointer;
  }

  &--hasMatrix3d {
    transform-origin: 0px 0px 0px; 
    transform-style: preserve-3d;
    outline: 1px solid transparent;
    border: 1px solid transparent;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    will-change: transform;
    img, video {
      outline: 1px solid transparent;
      border: 1px solid transparent;
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
    }
  }

  &--matrix3dDebugMode {
    background: rgba(255, 0, 0, 0.2);
    cursor: pointer;
    
    span {
      position: absolute;
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    img, video {
      display: none;
    }
  }
}
</style>