<template>
  <transition name="toggleContentComponent">
    <article class="programme" v-if="ready">
        <!-- TOPBAR -->
        <nav class="programme__topbar">
            <button class="button__close btnfx btnfx__hoverscale" @click="closeComponent">
                <img src="@/assets/img/ui/close_circle.png" />
            </button>
            <h3 v-html="getComponentTitle"></h3>
            <div class="programme__topbar_links">
                <button v-for="link in properties.links" :key="link.title" @click="openLink(link.link)">
                    <div>
                        <img :src="getSrc(link.icon.src)">
                    </div>
                    <h6 v-html="link.title"></h6>
                    <span v-html="link.subtitle"></span>
                </button>
            </div>
        </nav>

        <!-- PROGRAMME -->
        <div class="programme__center">
            <div class="programme__center_eventinfo">
                <img v-if="mediaType === 'image'" :src="getSrc(properties.icon.src)">
                <video v-else-if="mediaType === 'video'" autoplay muted loop playsinline webkit-playsinline disableRemotePlayback disablePictureInPicture>
                  <source :src="getSrc(properties.icon.src)">
                </video>
                <h3 v-html="properties.title"></h3>
                <div v-html="properties.text"></div>
            </div>

            
          <transition-group name="slide"
            :enter-active-class="animations[direction].enter"
            :leave-active-class="animations[direction].leave">
              <div class="programme__center_programme" v-for="day in computedProgramme" :key="day.title">
                  <div class="programme__center_programme_event" v-for="event in day.events" :key="event.title" :class="{'programme__center_programme_event--active': event.active, 'programme__center_programme_event--disabled': event.disabled}" @click="toggleEventText(event, day)">
                    <span v-html="formatTime(event.eventStart, event.eventEnd)"></span>
                    <h6 v-html="event.title.includes('XX') ? event.title.replace('XX', userTimeZone) : event.title"></h6>
                    <div v-html="event.text" v-show="event.active"></div>
                    <button v-if="event.link" v-show="event.active" v-html="event.linkTitle" @click="openLink(event.link)"></button>
                  </div>
              </div>
          </transition-group>
        </div>

        <!-- BOTTOM NAVIGATION -->
        <button class="programme__live" v-if="properties.liveSessionLink" @click="openLink(properties.liveSessionLink)">
          <span>{{ properties.liveSessionText || 'Live Sessions' }}</span>
        </button>
        <nav class="programme__nav" v-if="!hasOnlyOneDay">
            <div class="programme__nav_wrapper" :style="getNavPosition">
                <button class="programme__nav_button" v-for="navButton in days" :key="navButton.title" v-html="navButton.title" :class="{'programme__nav_button--active': navButton.id === activeDay}" @click="changeDayClick(navButton.id)"></button>
            </div>
            <div class="programme__nav_outline"></div>
        </nav>

        <div class="programme__background">
            <img :src="getCDNpath('assets/img/ui/programme-bg.jpg')">
        </div>
    </article>
  </transition>
</template>
 
<script>
export default {
  props: ["properties", "pageData", "slug", "componentID"],
  data() {
    return {
      ready: false,
      direction: "right",
      animations: {
        right: {
          enter: "slideInLeft",
          leave: "slideOutRight",
        },
        left: {
          enter: "slideInRight",
          leave: "slideOutLeft",
        },
      },

      days: [],
      activeDay: 0,
      userTimeZone: ""
    };
  },
  created() {
    this.userTimeZone = this.getUserTimeZone();
    this.formatDays();
  },
  mounted() {
    this.ready = true;
    window.addEventListener("keydown", this.keydownHandler);
  },
  watch: {
    activeDay() {
      this.days.forEach(day => {
        day.events.forEach(ev => {
          ev.active = false;
        })
      })
    }
  },
  methods: {
    formatDays() {
      this.days = this.properties.daysOfEvents.map((day, i) => {
        day.id = i;
        day.events.map(ev => {
          ev.active = false;
          ev.disabled = ev.text === "";
          return ev
        })
        return day;
      });
    },
    formatTime(eventStart, eventEnd) {
      const options = { timeZone: this.userTimeZone, hour: "2-digit", minute: "2-digit" };

      let start = new Date(eventStart);
      let formattedStart = start.toLocaleTimeString(undefined, options);

      let end = new Date(eventEnd);
      let formattedEnd = end.toLocaleTimeString(undefined, options);

      if (start.toISOString() === "0001-01-01T00:00:00.000Z") {
        return null; // quick fix to leave time blank, when there's no time set. Will be refactored later 
      }

      if (end.toISOString() === "0001-01-01T00:00:00.000Z") {
        formattedEnd = "late";
      }

      return `${formattedStart} - ${formattedEnd}`;
    },
    getUserTimeZone() {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return userTimeZone;
    },
    toggleEventText(event, day) {
      if(!event.active) {
        day.events.forEach(ev => {
          ev.active = false
        })
        event.active = true;
      } else {
        event.active = false;
      }
    },
    nextDay() {
      this.direction = "left";
      if (this.activeDay + 1 < this.days.length) {
        this.activeDay += 1;
      }
    },
    prevDay() {
      this.direction = "right";
      if (this.activeDay - 1 >= 0) {
        this.activeDay -= 1;
      }
    },
    keydownHandler(e) {
      switch (e.key) {
        case "ArrowLeft":
          this.prevDay();
          return;
        case "ArrowRight":
          this.nextDay();
          return;
      }
    },

    changeDayClick(id) {
      this.direction = id > this.activeDay ? "left" : "right";
      this.activeDay = id;
    },

    openLink(id) {
      if (id) 
        app.api.PageManager.openContentComponentFromID(id, false);
    },

    closeComponent() {
      this.ready = false;
      this.$emit("closeComponent", this.slug);
    },
    getSrc(src) {
      return app.api.Utils.getMediaPath(src);
    },
    getCDNpath(asset) {
      return app.api.Utils.getCDNpath(asset)
    },
  },
  unmounted() { 
    window.removeEventListener("keydown", this.keydownHandler);
  },
  computed: {
    computedProgramme() {
      return this.days.filter((day) => day.id === this.activeDay);
    },
    getNavPosition() {
        return {
            transform: `translateX(-${this.activeDay * 230}px)`
        }
    },
    hasOnlyOneDay() {
      return this.days.length <= 1;
    },
    mediaType() {
      if(this.properties.icon)
        return app.api.Utils.getMediaType(this.properties.icon.src)
    },
    getComponentTitle() {
      let data = app.api.PageManager.getContentComponentFromID(this.componentID);
      return data ? data.title : ""
    }
  },
};
</script>

<style lang="scss">
$navbuttonheight: 65px;
$navbuttonwidth: 230px;

.programme {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 927px;
  bottom: 0;
  color: #fff;

  &__topbar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 110px;
    min-height: 110px;
    width: 100%;
    padding: 0 108px;

    .button__close {
      left: 40px;
      margin-right: 30px;
    }

    h3 {
      font-size: 22px;
      text-transform: uppercase;
      margin-right: auto;
      margin-bottom: 6px; // better centering fix
    }

    &_links {
      button {
        position: relative;
        text-align: left;
        padding: 0 30px 0 100px;
        height: 56px;
        border-left: #c2c6d4 solid 1px;
        line-height: 18px;

        &:first-of-type { border-left: none; }

        & > div {        
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          left: 30px;
          top: 0;
          height: 54px;
          width: 54px;
          min-width: 54px;
          background-color: var(--main-color);
          background-clip: padding-box;
          border: 4px solid var(--main-color-transparent);
          border-radius: 50%;
          color: var(--main-color);
          transition-property: all;
          transition-duration: 0.3s;
          transition-timing-function: var(--tk-easeinout);

          img {
            width: 60%;
          }
        }

        h6 {
          font-weight: bold;
          font-size: 20px;
        }
        span {
          font-size: 15px;
        }

        &:hover {
          & div {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  &__center {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 1920px;

    &_eventinfo {
      position: absolute;
      left: 108px;
      top: 86px;
      text-align: left;
      width: 300px;

      & > img, & > video {
        width: 100%;
        margin-bottom: 26px;
      }

      h3 {
        font-size: 30px;
        line-height: 1.1;
        font-weight: bold;
        margin-bottom: 16px;
      }

      p { font-size: 22px; }

      div {
        line-height: 26px;
        font-weight: 200;
      }
    }


    &_programme {
        position: absolute;    
        top: 155px;
        left: 864px;
        width: 750px;
        text-align: left;
        font-size: 16px;
        animation-fill-mode: both;
        animation-duration: 0.4s;

        &_event {
          cursor: pointer;

          h6 { 
            font-size: inherit;
          }
          p {
            margin-bottom: 22px;
          }

          &>div {
            p {
              color: var(--light-pink);
            }
          }

          &--disabled { cursor: auto; }

  
          >span {
            position: absolute;
            right: calc(100% + 65px);
            padding-left: 32px;
            white-space: nowrap;
            color: var(--pink);
            font-weight: 500;
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */
            min-height: 23px;

            &::before {
              content: '';
              position: absolute;
              left: 0px;
              top: 50%;
              transform: translateY(-50%);
              width: 28px;
              height: 36px;
              background-image: url(@/assets/img/ui/arrow_programme_white.png);
              background-position: center;
              background-repeat: no-repeat;
              transform-origin: 50% 0%;
              opacity: 0.4; 
              transition: opacity ease-out 0.2s;
            }
          }

          > button {
            background: var(--main-color);
            padding: 10px 20px;
            border-radius: 50px;
            margin-bottom: 22px;

            &:hover {
              color: var(--main-color);
              background-color: var(--light-grey);
            }
          }

          &:hover {
            span {
              &::before {
                opacity: 1;
              }
            }
          }

          &--active {
            span {
              &::before {
                transform: rotate(90deg) translateY(-50%);
                opacity: 1;
              }
            }
          }

          &--disabled {
            span {
              pointer-events: none;
              &::before {
                content: none;
              }
            }
          }
        }
    }
  }

  &__live { // copied from #CalendarButton
    position: absolute;
    right: 45px;
    bottom: 40px;
    background: var(--main-color);
    color: #fff;
    padding: 0 24px 0 24px;
    justify-content: center;
    height: 53px;
    border-radius: 1000px;
    z-index: 2;
    transition-property: background color;
    transition-duration: 0.2s;
    transition-timing-function: ease;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        box-shadow: var(--shadow-medium);
        opacity: 0;
        transition-property: opacity;
        transition-duration: 0.2s;
        transition-timing-function: ease;
    }

    &:hover {
        background: #fff;
        color: var(--main-color);

        &::before {
            opacity: 1;
        }
    }
  }

  &__nav {
      position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 135px;
    min-height: 135px;

    &_wrapper {
        display: flex;
        height: $navbuttonheight;
        width: $navbuttonwidth;
        position: relative;
        transition: 0.4s transform var(--tk-easeinout);
    }

    &_outline {
      position: absolute;
      z-index: -1;
      border: solid 2px var(--dark-blue);
      height: $navbuttonheight;
      width: $navbuttonwidth;

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: -24px;
        transform: translateX(-50%);
        width: 23px;
        height: 16px;
        background-image: url(@/assets/img/ui/arrow_blue_up.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
      }
    }

    &_button {
        position: relative;
        font-size: 22px;
        min-height: 100%;
        min-width: 100%;

      p {
          margin-bottom: 0;

          sup {
              vertical-align: super;
              text-transform: uppercase;
              font-size: 14px;
          }
      }

      &--active {
        font-weight: bold;
      }
    }
  }

  // background
  &__background {
    position: absolute;
    inset: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
</style>