const config = {
    name: 'Real World Evidence Summit 2024',
    language: 'en',

    mediaFolder: '/media/',
    manifest: 'assets/json/@lang/manifest.json',

    cdnURL: 'https://novorwecdn.azureedge.net/rwe2024/',
    iframeHttp: {
        host: '',
        target: ''
    }
}

// for local dev environment on network
const ipRegex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$|^(?:[A-Fa-f0-9]{1,4}:){7}[A-Fa-f0-9]{1,4}$|^(?:[A-Fa-f0-9]{1,4}:){6}:[A-Fa-f0-9]{1,4}$|^(?:[A-Fa-f0-9]{1,4}:){5}(?::[A-Fa-f0-9]{1,4}){1,2}$|^(?:[A-Fa-f0-9]{1,4}:){4}(?::[A-Fa-f0-9]{1,4}){1,3}$|^(?:[A-Fa-f0-9]{1,4}:){3}(?::[A-Fa-f0-9]{1,4}){1,4}$|^(?:[A-Fa-f0-9]{1,4}:){2}(?::[A-Fa-f0-9]{1,4}){1,5}$|^[A-Fa-f0-9]{1,4}:(?::[A-Fa-f0-9]{1,4}){1,6}$|^:(?::[A-Fa-f0-9]{1,4}){1,7}$|^:(?::[A-Fa-f0-9]{1,4}){0,6}:[A-Fa-f0-9]{1,4}$|^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$|^(?:[0-9a-fA-F]{0,4}:){1,8}:?$/


// LOCAL DEV ENVIRONMENT
if (window.location.hostname === "localhost" || ipRegex.test(window.location.hostname)) {
    config.manifest = 'https://rweumbraco.azurewebsites.net/output/rwe2024/assets/json/@lang/manifest.json';
    config.mediaFolder = 'https://rweumbraco.azurewebsites.net/media/';
    config.cdnURL = '';

// ELECTRON PACKAGE
} else if (window.location.hostname === "") {
    config.mediaFolder = 'media/';

    
// BEHIND NOVO SSO (TEST)
} else if (window.location.hostname === "io-so-rwe-summit.novonordisk.com") {
    config.mediaFolder = "https://novorwecdn.azureedge.net/media/"

    config.iframeHttp.host = 'https://io-so-rwe-summit.novonordisk.com';
    config.iframeHttp.target = 'https://novorwecdn.azureedge.net/';
}

export default config