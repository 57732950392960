<template>
  <transition name="toggleContentComponent">
    <article class="fullscreenVideo" v-if="ready" :class="{'fullscreenVideo--videopaused': isVideoPaused}">
        <!-- TOPBAR -->
        <nav class="fullscreenVideo__topbar">
            <button class="button__close btnfx btnfx__hoverscale" @click="closeComponent">
                <img src="@/assets/img/ui/close_circle.png" />
            </button>
            <h3 v-html="getComponentTitle"></h3>
        </nav>

        <!-- CONTENT -->
        <div class="fullscreenVideo__center">
          <video ref="video"
              v-if="properties.video.src"
              :autoplay="properties.autoplay"
              :controls="properties.controls"
              :mute="properties.mute"
              :loop="properties.loop"
              :poster="posterUrl"
              @playing="isVideoPaused = false"
              @pause="isVideoPaused = true;"
              type="video/mp4"
              playsinline
              webkit-playsinline
              crossorigin
              disableRemotePlayback 
              disablePictureInPicture>
              <source :src="videoUrl">
          </video>
        </div>

        <div class="fullscreenVideo__background">
          <img :src="getCDNpath('assets/img/ui/video-bg.jpg')">
        </div>
    </article>
  </transition>
</template>

<script>
export default {
  props: [
    "properties",
    "pageData",
    "slug",
    "componentID"
  ],
  data() {
    return {
      ready: false,
      isVideoPaused: true,
      mutedAudioManager: false
    }
  },
  mounted() {
    this.ready = true;
    this.muteAudioManager();
  },
  methods: {
    muteAudioManager() {
      if(!this.$store.getters.getIsMuted) {
        this.mutedAudioManager = true;
        let event = new CustomEvent('muteAudio');
        window.dispatchEvent(event);
      }
    },
    unmuteAudioManager() {
      if(this.mutedAudioManager) {
        let event = new CustomEvent('unmuteAudio');
        window.dispatchEvent(event);
      } 
    },
    closeComponent() {
      this.$emit("closeComponent", this.slug)
    },
    getCDNpath(asset) {
      return app.api.Utils.getCDNpath(asset)
    },
  },
  unmounted() {
    this.unmuteAudioManager();
  },
  computed: {
    videoUrl() {
      return app.api.Utils.getMediaPath(this.properties.video.src)
    },
    posterUrl() {
      return app.api.Utils.getMediaPath(this.properties.poster.src)
    },
    getComponentTitle() {
      let pages = app.api.PageManager.getAllPages()
      let data = pages.find(page => page.id === this.componentID)

      return data ? data.title : ""
    },
  }
};
</script>

<style lang="scss">
.fullscreenVideo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 927px;
  bottom: 0;
  color: #fff;
  
  &__topbar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 110px;
    min-height: 110px;
    width: 100%;
    padding: 0 108px;

    .button__close {
      left: 40px;
      margin-right: 30px;
    }

    h3 {
      font-size: 22px;
      text-transform: uppercase;
      margin-right: auto;
      margin-bottom: 6px; // better centering fix
    }
  }

  &__center {
    position: relative;
    height: 100%;
    
    &>video,&>iframe {
      height: 750px;
    }
  }

  
  iframe {
    aspect-ratio: 16 / 9;
  }

  &::after {
    content: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(3px);
    width: 20px;
    height: 34px;
    pointer-events: none;
    background-image: url(@/assets/img/ui/play_large.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    z-index: 3;
    margin-left: 1px;
  }
  
  &::before {
    content: none;
    pointer-events: none;
    position: absolute;
    z-index: 3;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-20px);
    width: 80px;
    height: 80px;
    background: var(--main-color);
    background-clip: padding-box;
    border: 5px solid var(--main-color-transparent);
    border-radius: 80px;
  }

  &--videopaused {
      video {
          cursor: pointer;
      }

      &::after,&::before {
          content: '';
      }
  }

  // background
  &__background {
    position: absolute;
    inset: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
</style>