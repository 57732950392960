<template>
    <article class="Dialogue Dialogue--new">
        <div class="Dialogue__inputwrapper">
            <div class="Dialogue__inputwrapper_input">
                <textarea v-model="input" :placeholder="translations.new" @input="adjustInputHeight" @keydown.enter="inputEnter" ref="input" wrap="hard"></textarea>
            </div>
            <button @click="postNewDialogue">
                <img src="@/assets/img/ui/dialogue/post.png">
            </button>
        </div>
    </article>
</template>

<script>


export default {
  props: [
    "translations"
  ],
  data() {
    return {
        input: ""
    }
  },
  mounted() {
      this.$refs.input.focus()
  },
  methods: {
    adjustInputHeight() {
        const textarea = this.$refs.input;
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
    },
    inputEnter(e){
        if (e.keyCode == 13 && !e.shiftKey) {
            e.preventDefault();
            this.postNewDialogue();
        }
    },
    isValidInput(input) {
        return /[^ ]/.test(input.trim());
    },
    async postNewDialogue() {
        if(this.isValidInput(this.input)) {
            var isSuccess = await MicroServiceAPI.PostMessageBoardPost({
                MessageBoardId: this.translations.messageBoardID,
                MessageBoardPostId: "00000000-0000-0000-0000-000000000000",
                messageBoardParentPostId: "00000000-0000-0000-0000-000000000000",
                MessageSubject: this.input,
                Reactions: [],
                Replies: []
            })
            
            if(isSuccess) {
                window.dispatchEvent( new CustomEvent('addPoints', {"detail": {"points": 10}}));
                this.input = "";

                this.$emit("openCreatedDialogue", isSuccess);
            }
        }
    }
  },
  unmounted() {
  },
  computed: {
  }
};
</script>

<style lang="scss" scoped>

.Dialogue {
    justify-content: flex-end;

    &--new {
        // background-image: url(@/assets/img/ui/eventShape.png);
        background-repeat: no-repeat;
        background-position-y: 55%;
        background-position-x: 69%;
    }
}
</style>