<template>
  <li class="Dialogue__chat_message" :class="{'Dialogue__chat_message--own': message.user === user}">
      <div>
            <div>
                <span class="Dialogue__chat_message_user">{{message.user}}</span>
                <span class="Dialogue__chat_message_date">{{message.date}}</span>
            </div>
            <p v-html="message.text"></p>
            <button class="Dialogue__chat_message_likebutton" :class="{'Dialogue__chat_message_likebutton--reacted': hasReacted}" @click.once="likeMessage()" :key="hasPressedReact">
                <div>
                    <img src="@/assets/img/ui/dialogue/like.png">
                    {{message.likes}}
                </div>
            </button>
      </div>
  </li>
</template>
<script>
export default {
  props: [
    "message",
    "user",
    "messageBoardID",
    "messageBoardParentPostId"
  ],
    data() {
        return {
            hasPressedReact: 0
        }
    },
  methods: {
    async likeMessage() {
        if(!this.hasReacted) {
            await MicroServiceAPI.AddReactionToMessageBoardPost({
                MessageBoardId: this.messageBoardID,
                messageBoardParentPostId: this.message.messageBoardPostId,
                messageBoardPostReactionId: "00000000-0000-0000-0000-000000000000",
                messageBoardPostReactionTypeId: "00000000-0000-0000-0000-000000000001"
            })
        } else {
            await MicroServiceAPI.RemoveMessageBoardPostReaction({
                MessageBoardId: this.messageBoardID,
                messageBoardPostReactionId: this.hasReacted.messageBoardPostReactionId
            })
        }

        this.$emit("likedReply")
        setTimeout(() => {
            this.hasPressedReact++;
        }, 100);
    }
  },
  computed: {
      hasReacted() {
          return this.message.reactions.find(reaction => reaction.user === this.user)
      }
  }
};
</script>

<style lang="scss" scoped>
.Dialogue__chat_message {
    display: flex;
    margin: 32px 0;

    >div {
        position: relative;
        margin: 0 46px 0 18px;
        max-width: 455px;
        min-width: 250px;
        background: #fff;
        text-align: left;
        color: var(--dark-blue);

        p {
            padding: 14px 16px 14px 20px;
            margin-bottom: 0;
        }
        
        >div {
            position: absolute;
            display: flex;
            justify-content: space-between;
            bottom: 100%;
            width: 100%;
            padding-bottom: 6px;
            font-size: 13px;

            // span {
            //     &.Dialogue__chat_message_user {
            //     }
            // }
        }

        .Dialogue__chat_message_likebutton {
            position: absolute;
            padding: 5px 12px;
            font-size: 13px;
            right: -15px;
            bottom: -20px;
            background: var(--bright-blue);
            border-radius: 1000px;
            box-shadow: var(--shadow-tiny);
            transition: ease 0.2s transform;
            z-index: 2;

            >div {
                position: relative;
                z-index: 2;
                >img {
                    margin-right: 8px;
                    margin-bottom: -4px;
                }
            }

            &::before {
                content: none;
                position: absolute;
                left: -1px;
                top: -1px;
                height: calc(100% + 2px);
                width: calc(100% + 2px);
                border: solid 1px var(--main-color);
                z-index: -3;
                border-radius: inherit;
            }

            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border-radius: inherit;
                box-shadow: var(--shadow-small);
                opacity: 0;
                transition: ease 0.2s opacity;
                z-index: -2;
            }

            &:hover {
                transform: scale(1.05);

                &::after {
                    opacity: 0.5;
                }
            }

            &--reacted {
                &::before {
                    content: '';
                }
            }
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 12px;
            right: calc(100% - 1px);
            width: 0;
            height: 0;
            border-left: 20px solid transparent;
            border-right: 0px solid transparent;
            border-bottom: 20px solid #ffffff;
            pointer-events: none;
            z-index: 0;
        }
    }

    &:last-of-type {
        margin-bottom: 23px;
    }


    &--own {
        justify-content: flex-end;
        >div {
            background-color: var(--secondary-color);

            p {
                color: #fff;
            }

            >div {
                span {
                    &.Dialogue__chat_message_user {
                        opacity: 0;
                    }
                }
            }

            &::after {
                content: "";
                position: absolute;
                top: 12px;
                left: calc(100% - 1px);
                width: 0;
                height: 0;
                border-left: 0px solid transparent;
                border-right: 20px solid transparent;
                border-bottom: 20px solid var(--secondary-color);
                pointer-events: none;
                z-index: 0;
            }
        }
    }
}

</style>