<template>
    <router-link :to="homeURL" id="logo" :class="{'logo--onHomePage': onHomePage}">
        <img src="@/assets/img/ui/logo.png">
    </router-link>
</template>
<script>
export default {
    computed: {
        homeURL() {
            return app.api.PageManager.getRootPage().route
        },
        onHomePage() {
            this.$route
            return app.api.PageManager.onHomePage()
        }
    }
}
</script>
<style lang="scss" scoped>
$offset-top: 18px;

    #logo {
        position: absolute;
        width: 450px;
        top: $offset-top;
        right: 34px;
        z-index: 2;
        padding: 10px 20px;
        cursor: pointer;
        
        &>img {
            width: 100%;
        }

        &::after {
            content: '';
            position: absolute;
            height: 350px;
            width: 776px;
            right: -200px;
            top: calc(0px - $offset-top - 2px);
            background-image: url(@/assets/img/ui/logo-bg.png);
            background-position: center;
            background-repeat: no-repeat;
            transition: ease 0.25s opacity;
            opacity: 0;
            pointer-events: none;
            z-index: -1;
        }

        &.logo--onHomePage {
            &::after {
                opacity: 1;
            }
        }
    }
</style>