<template>
  <li>
    <div>
      <img src="@/assets/img/ui/star.png">
      <div>
        <span>{{formattedDate}}</span>
        <button @click="removeNews"><img src="@/assets/img/ui/close_blue.png"></button>
      </div>
    </div>
    <h3></h3>
    <p>{{news.title}}</p>
  </li>
</template>
<script>
export default {
  props: [
    "news",
    "formattedDate",
    "translations",
    "customData"
  ],
  methods: {
    removeNews() {
      this.$emit("removeNews", this.news)
    }
  }
};
</script>