<template>
  <transition name="toggleContentComponent">
    <article class="quiz" v-if="ready">

      <div class="text">
        <template v-if="questionView">
          <div v-html="currentQuestion.question"></div>
          <div class="guidetext" :class="{ hide: !questionView }" v-html="this.properties.guideText"></div>
        </template>
        <template v-else>
          <div v-if="this.currentQuestion.answeredCorrectly">
            <img class="point-star" src="@/assets/img/ui/dialogue/yellow-star.png" />
          <div class="header-correct">{{ currentQuestion.feedbackCorrect || this.properties.correctHeader }}</div>
          </div>
          <div v-if="!this.currentQuestion.answeredCorrectly">
            <img class="thumb-down" src="@/assets/img/ui/thumb-down.png" />
          <div class="header-incorrect">{{ currentQuestion.feedbackIncorrect || this.properties.incorrectHeader }}</div>
          </div>
        </template>
      </div>

      <div class="options" :class="{ freeze: !questionView }">
        <div class="answer" :class="{
          'selected': answer.selected,
          'correct': !questionView && answer.isCorrect,
          'incorrect': !questionView && !answer.isCorrect
        }" @click="selectAnswer(answer)" v-for="answer, i in currentQuestion.answers">
          <span class="letter">{{ answerLetters[i] }}</span>{{ answer.answer }}<img
            src="@/assets/img/ui/checkmark.png" />
        </div>
      </div>

      <div class="nav">
        <template v-if="questionView">
          <button :class="{ disabled: !anySelected }" @click="submitAnswer()">{{ properties.submitButton
          }}</button>
        </template>
        <template v-else>
          <button v-if="questions.length > 0" @click="getNewRandomQuestion()">{{ properties.newQuestionButton
          }}</button>

          <span v-if="questions.length == 0">{{properties.allAnsweredMessage}}</span>
        </template>
      </div>

      <button class="circle close btnfx btnfx__clicklight btnfx__hoverhalo" @click="closeComponent()"></button>

    </article>
  </transition>
</template>



<script>
export default {
  props: ["properties"],
  data() {
    return {
      ready: false,
      questions: [],
      currentQuestion: {},
      answeredQuestions: [],
      questionView: true,
      anySelected: false,
      answerLetters: ["A", "B", "C", "D", "E"]
    };
  },
  mounted() {

    // decorate questions with ids and result container
    this.initQuestions();

    this.getNewRandomQuestion();

    setTimeout(() => {
      this.ready = true;
    }, 1);

  },
  methods: {
    initQuestions() {

      // er vi midt i spørgsmålsrækken (i denne session)?
      var sessionQuestions = app.api.store.get(this.properties.guid);

      if (sessionQuestions && sessionQuestions.length > 0) {
        // ja - brug spørgsmål fra session 
        this.questions = sessionQuestions;
      } else {
        // nej - start forfra
        this.questions = this.properties.questions.map((question, i) => {
          // set id's
          question.id = "q" + i;
          question.answers = question.answers.map((answer, j) => {
            answer.id = question.id + "a" + j;
            answer.selected = false;
            return answer;
          });
          question.selectedAnswer = null;
          question.submitted = false;
          return question;
        });

      }
    },

    getNewRandomQuestion() {

      // need code here to filter out any already answered questions from user or session

      if (this.questions.length > 0) {

        var rndOfLeftQuestions = Math.floor(Math.random() * this.questions.length);

        this.currentQuestion = this.questions.splice(rndOfLeftQuestions, 1)[0]
        this.answeredQuestions.push(this.currentQuestion);

        this.questionView = true;
        this.anySelected = false;

      } else {

        console.log("no more qustions for you!")

      }

    },

    closeComponent() {
      this.ready = false;
      app.api.PageManager.closeAllContentComponents();
    },

    selectAnswer(a) {
      this.currentQuestion.answers.forEach(answer => {
        answer.selected = false;
        if (answer == a) {
          answer.selected = true;
        }
      });
      this.anySelected = true;
    },

    submitAnswer() {

      // find selected answer in current question
      var selectedAnswer = this.currentQuestion.answers.find(a => a.selected === true);

      if (selectedAnswer) {

        // goto resultView
        this.questionView = false;

        if (selectedAnswer.isCorrect) {
          this.currentQuestion.answeredCorrectly = true;
          window.dispatchEvent(new CustomEvent('addPoints', { "detail": { "points": 5 } }));
        } else {
          this.currentQuestion.answeredCorrectly = false;
        }

      } else {
        // no answer slected
      }

      app.api.store.set(this.properties.guid, this.questions);

    },

    getCDNpath(asset) {
      return app.api.Utils.getCDNpath(asset)
    }
  },
};
</script>

<style lang="scss">
.quiz {

  position: fixed;
  width: 460px;
  min-height: 650px;
  color: #fff;
  display: grid;
  grid-template-rows: auto auto 60px;
  background: #fff;
  font-size: 20px;

  .text {
    color: var(--dark-blue);
    width: 100%;
    padding: 80px 50px 0;
    min-height: 290px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .guidetext {
      font-style: italic;
      font-weight: normal;
      margin-bottom: 36px;
      opacity: 1;
      transition: opacity 0.4s;

      &.hide {
        opacity: 0;
      }
    }

    img.thumb-down {
      opacity: 0;
      margin-bottom: 30px;
      animation-name: thumbDownAnimation;
      animation-delay: 0.1s;
      animation-duration: 0.7s;
      animation-timing-function: var(--tk-easeinout);
      animation-fill-mode: forwards;
    }

    img.point-star {
      opacity: 0;
      margin-bottom: 30px;

      transform-origin: 50% 50%;
      animation-name: rotateStarAnimation;
      animation-delay: 0.1s;
      animation-duration: 1.8s;
      animation-timing-function: var(--tk-easeinout);
      animation-fill-mode: forwards;
    }


    .header-correct {
      color: var(--dark-blue);
      margin-bottom: 25px;
    }

    .header-incorrect {
      color: #e6553f;
      margin-bottom: 25px;
    }
  }


  .options {
    color: var(--main-color);
    padding: 50px;

    .answer {

      width: calc(100% - 30px);
      margin-left: 30px;
      text-align: left;
      min-height: 50px;
      border: var(--main-color) 1px solid;
      border-radius: 28px;
      margin-bottom: 15px;
      padding: 12px 22px 12px 22px;

      position: relative;
      cursor: pointer;
      transition: all .3s;

      &:last-child {
        margin-bottom: 0;
      }

      img {
        position: absolute;
        right: 15px;
        display: none;

        // maybe?
        right: -5px;
        display: none;
        top: -9px;
        background: var(--main-color);
        border-radius: 50%;
        padding: 2px;
      }

      .letter {
        position: absolute;
        top: 13px;
        left: -30px;
        // font-size: 15px;
        color: var(--main-color-light);
        // font-weight: bold;
      }

      &.incorrect {
        opacity: 0.4;
      }

      &.selected {
        opacity: 1;
        background-color: var(--bright-blue);

        // selected correct
        &.correct {
          color: #fff;
          background-color: var(--main-color);

          img {
            width: 25px;
            display: block;
          }
        }

        &.incorrect {
          color: #e6553f;
          background-color: transparent;
          border: #e6553f 1px solid;
        }

      }


      // unselected correct
      &.correct {

        img {
          width: 25px;
          display: block;
        }
      }

    }

    &.freeze {
      pointer-events: none;
    }
  }

  .nav {

    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row;

    button {
      background-color: var(--main-color);
      height: 60px;
      display: block;
      flex-grow: 1;
      transition: all 0.3s;
      border-left: 1px solid #fff;

      &.disabled {
        pointer-events: none;
        opacity: 0.4;
      }


      &:first-of-type {
        border-left: none;
      }

      &:hover {
        background-color: var(--dark-blue);
      }

      &:active {
        background-color: var(--light-blue);
        color: var(--main-color);
        transition: none;
      }

    }

    span {
      width: 100%;
      color: var(--main-color);
      font-size: 18px;
    }

  }

  button.close {
    position: absolute;
    right: -25px;
    top: -25px;

    // stolen defaultHotspot__button styling
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    min-width: 48px;
    background-color: var(--main-color);
    background-clip: padding-box;
    background-image: url(@/assets/img/ui/close_white.png);
    background-size: 18px;
    background-position: center;
    background-repeat: no-repeat;
    background-origin: content-box;
    border: 4px solid var(--main-color-transparent);
    border-radius: 50%;
    color: var(--main-color);
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: var(--tk-easeinout);
  }

}


@keyframes thumbDownAnimation {
  0% {
    opacity: 0;
    transform: translateY(-35px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);

  }

}

@keyframes rotateStarAnimation {
  0% {
      opacity: 0;
    transform: rotate(-430deg) scale(0.2);
  }

  100% {
      opacity: 1;
      transform: rotate(0deg) scale(1);

  }
}
</style>

