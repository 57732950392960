<template>
  <transition name="togglechatRoom">
    <article class="chatRoom" v-if="ready">
      <div class="chatRoom__left">
        <nav>
          <button class="toggleButton" :class="{'toggleButton--active': activeDialogueList === 1}">
            <span @click="changeActiveDialogueList(0)">{{properties.own}}</span>
            <span @click="changeActiveDialogueList(1)">{{properties.all}}</span>
          </button>

          <button class="chatRoom__newbutton" @click="createNewDialogue">
            <img src="@/assets/img/ui/dialogue/create-dialogue.png">
          </button>
        </nav>

        <div class="chatRoom__left_lists chatRoom__left_lists--own" v-if="activeDialogueList === 0">
          <div class="chatRoom__left_lists_list" :class="{'chatRoom__left_lists_list--active': ownCreatedInFocus}">
            <h3 @click="setListInFocus('created')">
              {{properties.created}}
              <button></button>
            </h3>
            <ul>
              <ListItem v-for="dialogue in shownCreated" :key="dialogue.messageBoardPostId" :dialogue="dialogue" :isInList="true" :class="{active: getIsActiveDialogue(dialogue)}" @click="setActiveDialogue(dialogue)"></ListItem>
            </ul>
            <div v-if="createdTotalPages > 1">
              <button @click="createdPageIndex -= 1" :class="{'chatRoom__left_lists_list_button--inactive': createdPageIndex === 0}"></button>
              <span>{{repliedRange}}</span>
              <button @click="createdPageIndex += 1" :class="{'chatRoom__left_lists_list_button--inactive': createdPageIndex === createdTotalPages - 1}"></button>
            </div>
          </div>
          <div class="chatRoom__left_lists_list" :class="{'chatRoom__left_lists_list--active': ownEngagedInFocus}">
            <h3 @click="setListInFocus('engaged')">
              {{properties.engaged}}
              <button></button>
            </h3>
            <ul>
              <ListItem v-for="dialogue in shownReplied" :key="dialogue.messageBoardPostId" :dialogue="dialogue" :isInList="true" :class="{active: getIsActiveDialogue(dialogue)}" @click="setActiveDialogue(dialogue)"></ListItem>
            </ul>
            <div v-if="repliedTotalPages > 1">
              <button @click="repliedPageIndex -= 1" :class="{'chatRoom__left_lists_list_button--inactive': repliedPageIndex === 0}"></button>
              <span>{{repliedRange}}</span>
              <button @click="repliedPageIndex += 1" :class="{'chatRoom__left_lists_list_button--inactive': repliedPageIndex === repliedTotalPages - 1}"></button>
            </div>
          </div>
        </div>

        <div class="chatRoom__left_lists chatRoom__left_lists--all" v-else>
          <div class="chatRoom__left_lists_list">
            <h3>{{properties.mostEngaged}}</h3>
            <ul>
              <ListItem v-for="dialogue in mostEngaged" :key="dialogue.messageBoardPostId" :dialogue="dialogue" :isInList="true" :class="{active: getIsActiveDialogue(dialogue)}" @click="setActiveDialogue(dialogue)"></ListItem>
            </ul>
          </div>
          <div class="chatRoom__left_lists_list">
            <h3>{{properties.newest}}</h3>
            <ul>
              <ListItem v-for="dialogue in shownNewest" :key="dialogue.messageBoardPostId" :dialogue="dialogue" :isInList="true" :class="{active: getIsActiveDialogue(dialogue)}" @click="setActiveDialogue(dialogue)"></ListItem>
            </ul>
            <div v-if="newestTotalPages > 1">
              <button @click="newestPageIndex -= 1" :class="{'chatRoom__left_lists_list_button--inactive': newestPageIndex === 0}"></button>
              <span>{{newestRange}}</span>
              <button @click="newestPageIndex += 1" :class="{'chatRoom__left_lists_list_button--inactive': newestPageIndex === newestTotalPages - 1}"></button>
            </div>
          </div>
        </div>
        
      </div>
      <div class="chatRoom__dialogue">
        <h3 class="chatRoom__dialogue_title" v-html="properties.title"></h3>
        <DialogueNew v-if="newDialogue" :translations="properties" @openCreatedDialogue="openCreatedDialogue"></DialogueNew>
        <Dialogue :dialogue="activeDialogue" v-else-if="activeDialogue" :translations="properties" @repliedToPost="repliedToPost" @updateDialogueData="updateDialogueData" @deletedPost="deletedPost" :user="username"></Dialogue>
        <div class="chatRoom__dialogue_fallback" v-if="!newDialogue && !activeDialogue">
          <img src="@/assets/img/ui/dialogue/blue-dialogue-starter.png">
          <p>{{properties.nonOpen}}</p>
        </div>
      </div>

      <button class="Hotspot defaultHotspot btnfx" @click="closeComponent">
        <div class="defaultHotspot__button btnfx__clicklight btnfx__hoverhalo">
          </div>
          <div class="defaultHotspot__label show">
              <span v-html="properties.return"></span>
          </div>
      </button>
    </article>
  </transition>
</template>

<script>

export default {
  props: [
    "properties",
    "pageData",
    "slug",
    "componentID"
  ],
  data() {
    return {
      ready: false,

      username: "",

      activeDialogueList: 1,
      noDialogueSelected: false,

      ownCreatedInFocus: false,
      ownEngagedInFocus: true,

      activeDialogue: "",
      newDialogue: false,

      mostEngaged: [],

      newest: [],
      newestPostsPerPage: 4,
      newestPageIndex: 0,

      created: [],
      createdPostsPerPage: 7,
      createdPageIndex: 0,

      replied: [],
      repliedPostsPerPage: 7,
      repliedPageIndex: 0,
    }
  },
  created() {
    this.getUserName();
    this.getMessageBoardPosts();
    this.getUserDialoguePosts();
  },
  mounted() {
    this.ready = true
  },
  methods: {
    async getUserName(){
      this.username = await MicroServiceAPI.GetUsername()
    },
    async getMessageBoardPosts() {
      var messageBoardData = {}
      messageBoardData = await MicroServiceAPI.GetMessageBoardPostsSimple({MessageBoardId: this.properties.messageBoardID})
      //console.log(messageBoardData)
      var mostEngagedMessageBoardData = {}
      mostEngagedMessageBoardData = await MicroServiceAPI.GetMessageBoardPostsMostEngaged({MessageBoardId: this.properties.messageBoardID})

      
      this.newest = [];
      this.mostEngaged = [];

      //NEWEST
      messageBoardData.messageBoardPosts.forEach(post => {
        this.newest.push(this.formatPost(post, false))
      })

      //MOST ENGAGED
      mostEngagedMessageBoardData.forEach(post => {
        this.mostEngaged.push(this.formatPost(post, false))
      })

      this.mostEngaged = this.mostEngaged.splice(0,3)
      this.shownNewest
    },
    async getUserDialoguePosts() {
      var postsByUser = []
      postsByUser = await MicroServiceAPI.GetMessageBoardPostsByUser({MessageBoardId: this.properties.messageBoardID})
      var repliedByUser = []
      repliedByUser = await MicroServiceAPI.GetMessageBoardPostsWithRepliesByUser({MessageBoardId: this.properties.messageBoardID})
      //console.log(repliedByUser)

      this.created = [];
      this.replied = [];
  

      postsByUser.forEach(post => {
        this.created.push(this.formatPost(post, false))
      })

      repliedByUser.forEach(post => {
        this.replied.push(this.formatPost(post, false))
      })
    },
    
    formatPost(post, justCreated) {
      return {
        user: post.user,
        date: this.formatDate(post.created),
        title: post.messageSubject,
        likes: post.reactionCount,
        comments: post.replyCount,
        messageBoardPostId: post.messageBoardPostId,
        justCreated: justCreated
      }
    },
    formatDate(dateString) {
      const options = { 
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
      };
      const langCode = app.api.store.get("lang");

      // COMPENSATE FOR THE DATESTRING NOT BEING FORMATTED AS UTC LIKE UMBRACO DATESTRINGS
      if (!dateString.includes("Z")) {
        dateString = dateString + "Z"
      }

      const date = new Date(dateString);
      
      const formattedDate = date.toLocaleDateString(langCode, options);

      return formattedDate;
    },
    deletedPost() {
      this.activeDialogue = ""
      this.updateDialogueData()
    },
    repliedToPost() {
      this.updateDialogueData()
    },
    updateDialogueData() {
      this.getMessageBoardPosts();
      this.getUserDialoguePosts();
    },
    changeActiveDialogueList(i) {
      this.activeDialogueList = i
      /* RESET IF NEEDED
      this.newestPageIndex = 0;
      this.createdPageIndex = 0;
      this.repliedPageIndex = 0;
      this.ownCreatedInFocus = false;
      this.ownEngagedInFocus = true; */
      this.resetDialogueState()
    },
    setActiveDialogue(dialogue) {
      if (dialogue.messageBoardPostId == this.activeDialogue.messageBoardPostId)
        return

      this.newDialogue = false;
      this.activeDialogue = null;
      
      setTimeout(() => {
        this.activeDialogue = dialogue;
      }, 1);
    },
    getIsActiveDialogue(dialogue) {
      if(this.activeDialogue === null)
        return false;

      return this.activeDialogue.messageBoardPostId == dialogue.messageBoardPostId;
    },
    createNewDialogue() {
      this.activeDialogueList = 0;
      this.activeDialogue = ""
      this.newDialogue = true;
    },
    async openCreatedDialogue(createdDialogue) {
      await this.getUserDialoguePosts();
      await this.getMessageBoardPosts();

      this.setActiveDialogue(this.formatPost(createdDialogue, true))
    },
    resetDialogueState() {
      this.activeDialogue = ""
      this.newDialogue = false;
    },
    setListInFocus(list) {
      switch(list) {
        case "created":
          this.ownCreatedInFocus = !this.ownCreatedInFocus
          this.ownEngagedInFocus = false
          break;

        case "engaged":
          this.ownCreatedInFocus = false
          this.ownEngagedInFocus = !this.ownEngagedInFocus
          break;
      }
    },
    closeComponent() {
      this.$emit("closeComponent", this.slug)
    }
  },
  unmounted() {
  },
  computed: {
    //NEWEST PAGINATION
    shownNewest() {
      const startIndex = this.newestPageIndex * this.newestPostsPerPage;
      const endIndex = startIndex + this.newestPostsPerPage;
      return this.newest.slice(startIndex, endIndex);
    },
    newestTotalPages() {
      return Math.ceil(this.newest.length / this.newestPostsPerPage);
    },
    newestRange() {
      const startIndex = this.newestPageIndex * this.newestPostsPerPage + 1;
      const endIndex = Math.min((this.newestPageIndex + 1) * this.newestPostsPerPage, this.newest.length);
      return `${startIndex} - ${endIndex}`;
    },


    //CREATED PAGINATION
    shownCreated() {
      const startIndex = this.createdPageIndex * this.createdPostsPerPage;
      const endIndex = startIndex + this.createdPostsPerPage;
      return this.created.slice(startIndex, endIndex);
    },
    createdTotalPages() {
      return Math.ceil(this.created.length / this.createdPostsPerPage);
    },
    createdRange() {
      const startIndex = this.createdPageIndex * this.createdPostsPerPage + 1;
      const endIndex = Math.min((this.createdPageIndex + 1) * this.createdPostsPerPage, this.created.length);
      return `${startIndex} - ${endIndex}`;
    },



    //REPLIED PAGINATION
    shownReplied() {
      const startIndex = this.repliedPageIndex * this.repliedPostsPerPage;
      const endIndex = startIndex + this.repliedPostsPerPage;
      return this.replied.slice(startIndex, endIndex);
    },
    repliedTotalPages() {
      return Math.ceil(this.replied.length / this.repliedPostsPerPage);
    },
    repliedRange() {
      const startIndex = this.repliedPageIndex * this.repliedPostsPerPage + 1;
      const endIndex = Math.min((this.repliedPageIndex + 1) * this.repliedPostsPerPage, this.replied.length);
      return `${startIndex} - ${endIndex}`;
    }
  }
};
</script>

<style lang="scss">
$leftwidth: 380px;

.chatRoom {
  display: flex;
  position: absolute;
  right: 0;
  width: 960px;
  background: var(--light-blue);
  height: 100%;
  box-shadow: var(--shadow-omega);


  &__left {
    display: flex;
    flex-direction: column;
    position: relative;
    width: $leftwidth;
    min-width: $leftwidth;
    padding: 46px 30px 0 32px;

    >nav {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      flex-grow: 0;

      .toggleButton {
        width: 132px;
      }

      .chatRoom__newbutton {
        transition: ease 0.2s transform;

        &:hover {
          transform: scale(1.08);
        }
      }
    }


    &_lists {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      &_list {
        position: relative;
        display: flex;
        flex-direction: column;

        h3 {
          position: relative;
          display: flex;
          align-items: center;
          font-size: 16px;
          padding: 18px 0;
          text-align: left;
          color: var(--dark-blue);

          button {
            position: absolute;
            right: 0;
            height: 40px;
            width: 30px;
            background-image: url(@/assets/img/ui/dialogue/arrow-fold-arrow.png);
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s var(--tk-easeinout);
          }
        }

        ul {
          position: relative;
          list-style-type: none;
          padding-left: 0;
          margin-bottom: 0;
          flex-grow: 1;

          li {
            &::after {
              width: $leftwidth;
            }

            &::before {
              content: "";
              position: absolute;
              width: $leftwidth;
              height: 100%;
              background-color: rgba(#fff, .15);
              z-index: -1;
              top: 0;
              left: -32px;
              opacity: 0;
            }
            
            &.active {
              cursor: default;
              &::before {
                
                opacity: 1;
              }
            }

            &:hover {
              &::before {
                opacity: 1;
              }
            }
          }
        }

        &>div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 68px;

          button {
            width: 40px;
            height: 40px;
            background-image: url(@/assets/img/ui/dialogue/arrow-fold-in.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 12px;
            transition-timing-function: ease;
            transition-duration: 0.2s;
            transition-property: transform, background-size;

            &:first-of-type {
              transform: rotate(-90deg);
              margin-left: -15px;

              &:hover {
                transform: scale(1.15) rotate(-90deg);
              }
            }

            &:last-of-type {
              transform: rotate(90deg);
              margin-right: -15px;

              &:hover {
                transform: scale(1.15) rotate(90deg);
              }
            }

            &.chatRoom__left_lists_list_button--inactive {
              opacity: 0.2;
              pointer-events: none;
            }

          }

          span {
            font-size: 13px;
            color: var(--dark-blue);
          }
        }

        &::after {
          content: '';
          position: absolute;
          width: $leftwidth;
          height: 2px;
          background-color: var(--dark-blue);
          left: -32px;
          bottom: 0;
        }

        &:last-of-type {
          &::after {
            content: none;
          }
        }
      }

      &--own {
        h3 {
          cursor: pointer;
          
          &:hover{
            button {
              background-size: 17px;
            }
          }
        }

        .chatRoom__left_lists_list {
          max-height: 60px;
          overflow-y: clip;
          overflow-x: visible;
          flex-grow: 1;

          &::after {
            content: '' !important;
          }

          &--active {
            max-height: 10000px;
            h3 {
              button {
                transform: rotate(180deg);
              }
            }
          }
        }
      }

      &--all {
        .chatRoom__left_lists_list {
          &:last-of-type {
            flex-grow: 1;
          }
        }

      }
    }
  }
  
  &__dialogue {
    position: relative;
    z-index: 2;
    flex-grow: 1;
    background: var(--bright-blue);
    box-shadow: var(--shadow-big);

    &_title {
      font-size: 22px;
      font-weight: bold;
      text-align: right;
      width: 520px;
      height: 88px;
      padding: 36px 0 20px;
      border-bottom: 1px solid rgba(#001965, .15);
      margin: 0 30px;
    }

    &_fallback {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      opacity: 0;

      animation-name: dialogueFallbackIntro;
      animation-delay: 0.1s;
      animation-duration: 0.4s;
      animation-timing-function: ease;
      animation-fill-mode: forwards;
      

      img {
        margin-bottom: 18px;
      }

      p {
        font-size: 16px;
        color: var(--dark-blue);
      }
    }
  }


  .defaultHotspot {
    right: calc(100% + 32px);
    bottom: 30px;

    .defaultHotspot__button {
      background-image: url(@/assets/img/ui/arrow_back.png);
      background-size: 30%;
      transform: rotate(0deg) !important;
    }
  }
}



.togglechatRoom-enter-active {
  transition-property: transform, opacity;
  transition-duration: 0.4s;
  transition-timing-function: var(--tk-easeinout);
}

.togglechatRoom-leave-active {
  transition-property: transform, opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease;
}

.togglechatRoom-enter-from {
  transform: translateX(100%);
  opacity: 0;
}

.togglechatRoom-leave-to {
    opacity: 0;
}


@keyframes dialogueFallbackIntro {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>