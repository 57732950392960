<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="2337.293" height="2874.704" viewBox="0 0 2337.293 2874.704">
        <defs>
            <clipPath id="clip-path">
            <path id="Path_15695" data-name="Path 15695" d="M145.031,729.735C-97.944,1058.366-28.533,1521.77,300.137,1764.771c241.612,178.636,556.123,188.383,802.284,50.433,208.708-106.814,460.185,36.723,472.409,272.827,5.69,109.981,58.7,216.609,154.165,287.191a379.8,379.8,0,0,0,225.874,74.769h1.314c116.637-.209,231.686-53.711,306.343-154.689,125.264-169.424,89.468-408.295-79.958-533.556-95.464-70.582-212.948-90.009-319.767-63.21-229.406,57.565-440.495-141.06-399.274-372.053a2.854,2.854,0,0,1,.141-.579c59.312-275.522-42.249-572.827-283.605-751.274A736.664,736.664,0,0,0,740.675,429.589c-226.715.008-450.5,103.836-595.644,300.146" transform="translate(0 -429.589)" fill="#3b97de"/>
            </clipPath>
            <clipPath id="clip-path-2">
            <path id="Path_15696" data-name="Path 15696" d="M1049.729,189.5c-110.61,149.6-118.687,343.446-36.806,497.45a.893.893,0,0,0,.107.229c106.883,216.79-76.267,464.506-314.849,425.844-.088,0-.161-.028-.249-.032-171.242-33.143-354.2,31.4-464.812,181.006-153.427,207.513-109.561,500.121,97.956,653.546s500.127,109.593,653.552-97.92c110.608-149.6,118.693-343.414,36.824-497.438-107.183-216.919,76.17-464.909,314.995-426.041,171.253,33.125,354.182-31.417,464.79-181.02C1954.661,537.614,1910.8,245.008,1703.28,91.585A465.2,465.2,0,0,0,1425.828,0c-143.158,0-284.46,65.554-376.1,189.5" transform="translate(-141.551 -0.003)" fill="#3b97de"/>
            </clipPath>
            <clipPath id="clip-path-3">
            <path id="Path_15698" data-name="Path 15698" d="M730.355,500.5a63.8,63.8,0,1,0,25.4-5.3,63.852,63.852,0,0,0-25.4,5.3" transform="translate(-691.914 -495.197)" fill="none"/>
            </clipPath>
            <linearGradient id="linear-gradient" x1="-7.497" y1="10.347" x2="-7.486" y2="10.347" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#ff8d00"/>
            <stop offset="0.492" stop-color="#ff00d0"/>
            <stop offset="1" stop-color="blue"/>
            </linearGradient>
        </defs>
        <g id="Group_3867" data-name="Group 3867" transform="translate(0 -0.003)">
            <g id="Group_3853" data-name="Group 3853" transform="translate(0 0)">
            <g id="Group_3852" data-name="Group 3852" transform="translate(0)" clip-path="url(#clip-path)">
                <rect id="Rectangle_11078" data-name="Rectangle 11078" width="2787.659" height="3051.285" transform="translate(-783.904 1751.8) rotate(-68.616)" fill="#3b97de"/>
            </g>
            </g>
        </g>
    </svg>
</template>


<style lang="scss" scoped>
svg {
    pointer-events: none;
    position: absolute;
    left: -820px;
    top: -240px;
    z-index: -1;
}
</style>