<template>
    <article class="Dialogue">
        <ListItem id="Dialogue__ListItem" :dialogue="dialogue" :user="user" :isInList="false" :translations="translations" @likedPost="likedPost" @deletedPost="deletedPost" @focusInput="focusInput"></ListItem>

        <ul class="Dialogue__chat" :class="{'Dialogue__chat--empty': chat.length == 0}">
            <DialogueChatMessage v-for="message in chat" :key="message.text + message.date" :message="message" :user="user" :messageBoardParentPostId="dialogue.messageBoardPostId" :messageBoardID="translations.messageBoardID" @likedReply="likedReply"></DialogueChatMessage>
           

            <div v-if="dialogue.justCreated" class="Dialogue__chat_justCreated">
                <img src="@/assets/img/ui/dialogue/blue-star.png">
                <h6>10 {{translations.points}}</h6>
                <p>{{translations.reward}}</p>
            </div>
            <div v-else-if="emptyChat && !dialogue.justCreated">
                <img src="@/assets/img/ui/dialogue/blue-dialogue-starter.png">
                <p>{{translations.nonOpen}}</p>
            </div>
        </ul>

        <div class="Dialogue__inputwrapper">
            <div class="Dialogue__inputwrapper_input">
                <textarea v-model="input" :placeholder="translations.answer" wrap="hard" @input="adjustInputHeight" @keydown.enter="inputEnter" ref="input"></textarea>
            </div>
            <button @click="replyToPost">
                <img src="@/assets/img/ui/dialogue/post.png">
            </button>
        </div>
    </article>
</template>

<script>
export default {
  props: [
    "dialogue",
    "translations",
    "user"
  ],
  data() {
    return { 
        chat: [],
        emptyChat: false,

        input: "",
        inputLocked: false
    }
  },
  mounted() {
      this.getDialogueReplies()

      if(this.dialogue.justCreated)
        setTimeout(() => {
            this.dialogue.justCreated = false
        }, 10000);
  },
  methods: {
    adjustInputHeight() {
        const textarea = this.$refs.input;
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
    },
    async getDialogueReplies() {
        var dialogueData = {}
        dialogueData = await MicroServiceAPI.GetMessageBoardPost({MessageBoardPostId: this.dialogue.messageBoardPostId})
        //console.log(dialogueData)

        this.chat = []
        this.dialogue.reactions = dialogueData.reactions
        this.dialogue.likes = dialogueData.reactionCount
        this.dialogue.comments = dialogueData.replyCount

        if(dialogueData !== {} || dialogueData !== undefined || dialogueData !== null)
            dialogueData.replies.forEach(reply => {
                this.chat.push(this.formatPostReply(reply))
            })

        if(this.chat.length == 0) 
            this.emptyChat = true;
    },
    formatPostReply(reply) {
        var obj = {
            user: reply.user,
            text: reply.messageBody,
            date: this.formatDate(reply.lastModified),
            likes: reply.reactionCount,
            messageBoardPostId: reply.messageBoardPostId,
            reactions: reply.reactions
        }

        return obj
    },
    formatDate(dateString) {
        const options = { 
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        };
        const langCode = app.api.store.get("lang");

        if (!dateString.includes("Z")) {
            dateString = dateString + "Z"
        }

        const date = new Date(dateString);
        
        const formattedDate = date.toLocaleDateString(langCode, options);

        return formattedDate;
    },

    inputEnter(e){
        if (e.keyCode == 13 && !e.shiftKey) {
            e.preventDefault();
            this.replyToPost();
        }
    },

    isValidInput(input) {
        return /[^ ]/.test(input.trim());
    },

    async replyToPost() {
        if(this.isValidInput(this.input)) {
            await MicroServiceAPI.PostMessageBoardPost({
                MessageBoardId: this.translations.messageBoardID,
                MessageBoardParentPostId: this.dialogue.messageBoardPostId,
                MessageBoardPostId: "00000000-0000-0000-0000-000000000000",
                MessageBody: this.input,
                Reactions: [],
                Replies: []
            })

            this.emptyChat = false;
            await this.getDialogueReplies()
            this.input = ""
            this.$emit("repliedToPost")
            this.updateDialogueData()
        }
    },
    deletedPost() {
        this.$emit("deletedPost")
    },
    likedPost() {
        this.getDialogueReplies()
        this.updateDialogueData()
    },
    likedReply() {
        this.getDialogueReplies();
    },
    updateDialogueData() {
        this.$emit("updateDialogueData", this.dialogue)
    },
    focusInput() {
      this.$refs.input.focus();
    }
  },
  unmounted() {
  },
  computed: {
  }
};
</script>

<style lang="scss">
    .Dialogue {    
        display: flex;
        flex-direction: column;
        height: calc(100% - 104px);
        padding: 36px 30px 36px 30px;

        &__chat {
            display: flex;
            flex-direction: column-reverse;
            margin-bottom: 24px;
            list-style-type: none;
            padding: 26px 0 0 0;
            overflow-y: auto;
            overflow-x: hidden;
            flex-grow: 1;
            
            &>div {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                width: 580px;
                font-size: 16px;
                color: var(--dark-blue);
                opacity: 0;

                animation-name: dialogueFallbackIntro;
                animation-delay: 0.1s;
                animation-duration: 0.4s;
                animation-timing-function: ease;
                animation-fill-mode: forwards;

                h6 {
                    font-weight: bold;
                }

                img {
                    margin-bottom: 18px;
                }

                &.Dialogue__chat_justCreated {
                    img {
                        transform-origin: 50% 50%;
                        animation-name: rotateStarAnimation;
                        animation-delay: 0.1s;
                        animation-duration: 1.6s;
                        animation-timing-function: var(--tk-easeinout);
                        animation-fill-mode: forwards;
                    }
                }
            }

            &--empty {
                justify-content: center;
            }
        }

        &__inputwrapper {
            display: flex;
            align-items: flex-end; 

            &_input {
                position: relative;
                flex-grow: 0;
                min-height: 70px;
                width: 473px;
                flex-grow: 0;

                textarea {
                    position: relative;
                    left: 0;
                    bottom: 0;
                    padding: 12px 16px 12px 20px;
                    text-align: left;
                    color: var(--main-color);
                    font-size: 16px;
                    height: 100%;
                    width: 100%;
                    background: #fff;
                    resize: none;
                    overflow: hidden;

                    &::placeholder {
                        opacity: 0.4;
                    }
                }

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 12px;
                    left: calc(100% - 1px);
                    width: 0;
                    height: 0;
                    border-left: 0px solid transparent;
                    border-right: 20px solid transparent;
                    border-bottom: 20px solid #ffffff;
                    pointer-events: none;
                    z-index: -1;
                }
            }

            button {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin: 0 0 6px 32px;
                flex-grow: 1;
                height: 35px;
                
                img {
                    transition: var(--tk-easeinout) 0.6s transform;
                }

                &:hover {
                    img {
                        transform: translateX(3px);
                    }
                }
            }
        }
    }

    #Dialogue__ListItem {
        cursor: default;
        height: auto;
        padding: 14px 0 18px 0;

        button {
            pointer-events: all;
        }

        p {
            margin-bottom: 10px;
            font-size: 20px;
            line-height: 26px;
            height: auto;
            min-height: 56px;
            -webkit-line-clamp: 100;
        }

        &:last-of-type {
            &::after {
                content: "";
                width: 100%;
                left: 0;
                top: 100%;
            }
        }
    }


@keyframes rotateStarAnimation {
  0% {
      opacity: 0;
    transform: rotate(-430deg) scale(0.2);
  }

  100% {
      opacity: 1;
      transform: rotate(0deg) scale(1);

  }
}
</style>