<template>
    <article id="Newsboard" :class="{'Newsboard--open': newsboardOpen}">
        <button class="Newsboard__button" :class="{'Newsboard__button--hasnews': unseenCounter > 0}" @click="toggleNewsboard">
            <div class="Newsboard__button_icon">
              <video muted loop autoplay disableRemotePlayback disablePictureInPicture >
                <source :src="getCDNpath('assets/video/ui/newsboard-icon-anim.mp4')" type="video/mp4"  />
              </video>
            </div>
            <span>{{unseenCounter > 0 ? unseenCounter : ''}}</span>
        </button>
        
        
        <aside class="Newsboard__window" v-if="newsboardOpen">
            <h2>{{translations.newsboardTitle}}</h2>
            <div>
              <ul>
                  <component :is='getNewsComponent(news)' v-for='(news, i) in shownNewsboard' :news="news" :formattedDate="formatDate(news.date)" :translations="translations" :key='news.type + i' :customData="news.customData" @closePopup="closePopup" @removeNews="hideNews"></component>
              </ul>
            </div>
            <button class="Newsboard__window_close" @click="toggleNewsboard">
              <img src="@/assets/img/ui/close_white.png">
            </button>
        </aside>
    </article>
</template>
<script>
import { markRaw } from "vue";
const moment = require('moment-timezone');

export default {
  data() {
    return {
      newsboardComponents: {},
      newsboardOpen: false,
      userTimeZone: "",
      language: "en",

      newsboard: [],
    };
  },
  created() {
    this.resetNewsboard();
    this.language = app.api.store.get("lang");

    if(!app.api.store.get("Newsboard")){
      this.saveNewsboard();
      this.createNewUserNews();
    }

    this.userTimeZone = this.getUserTimeZone();
    this.loadNewsboard();
    this.importNewsComponents();
    this.clearOldNews();
    this.checkIfNewNews()
  },
  mounted() {
    this.saveNewsboard()
  },
  watch: {
    $route(val) {
      this.clearOldNews();
      this.checkIfNewNews();
      this.saveNewsboard()
      this.newsboardOpen = false;
    }
  },
  methods: {
    importNewsComponents() {
      // Use require.context to dynamically import components from the ./hotspot-components subfolder
      const componentContext = require.context("./Newsboard-components/", true, /\.vue$/);

      // Iterate over each component path and extract the component name
      componentContext.keys().forEach((componentPath) => {
        const componentName = componentPath.replace(/^.\/(.*)\.\w+$/, "$1");

        // Import the component and mark it as non-reactive using markRaw
        this.newsboardComponents[componentName] = markRaw(
          componentContext(componentPath).default
        );
      });
    },
    resetNewsboard() {
      // CRUDE RESET OF OLD NEWSBOARDS TO MAKE SURE THE EVENTS HAVE THE RIGHT DATA FORMAT. (check if they have variable ".hidden")
      if(app.api.store.get("Newsboard")) {
        var savedNewsboard = app.api.store.get("Newsboard")
        savedNewsboard.forEach(news => {
          if(!news.hasOwnProperty("hidden"))
            app.api.store.remove("Newsboard")
        })
      }
    },
    closePopup() {
      this.newsboardOpen = false;
    },
    getNewsComponent(news) {
      const componentName = news.type;
      return this.newsboardComponents[componentName];
    },
    toggleNewsboard() {
      this.newsboard.forEach(news => news.seen = true)
      this.saveNewsboard()
      this.newsboardOpen = !this.newsboardOpen;
    },
    loadNewsboard() {
      var savedNewsboard = app.api.store.get("Newsboard")
      savedNewsboard.forEach(news => this.newsboard.push(news))
    },
    saveNewsboard() {
      app.api.store.set("Newsboard", this.newsboard)
    },
    clearOldNews() {
      var now = moment()

      this.newsboard.forEach(news => {
        if(now.isAfter(news.removeDate)) 
          this.removeNews(news)
      })
    },
    removeNews(news) {
      let indexToRemove = this.newsboard.indexOf(this.newsboard.find(newspost => news == newspost));

      if (indexToRemove !== -1) {
          this.newsboard.splice(indexToRemove, 1);
      }
    },
    hideNews(news) {
      let indexToHide = this.newsboard.indexOf(this.newsboard.find(newspost => news == newspost));

      if (indexToHide !== -1) {
        this.newsboard[indexToHide].hidden = true;
      }
      this.saveNewsboard();
    },
    isDateInNextMinutes(date, minutes) {
      const millisecondsInMinutes = minutes * 60 * 1000; // Convert minutes to milliseconds

      const currentMoment = moment();
      const timeDifference = date.diff(currentMoment);
      
      return timeDifference > 0 && timeDifference <= millisecondsInMinutes;
    },

    checkIfNewNews() {
      this.checkCustomMessages();
      this.checkIfAnyMessageBoardEvents();
    },

    checkIfNewsExist(type, title, subtitle = "") {
      for (const entry of this.newsboard) {
        if (entry.type === type && entry.title === title && entry.subtitle === subtitle) 
          return true; // Matching entry found
      }

      return false;
    },
    createNewsboardComponent(type, title, subtitle = "", removeDate, customData = {}) {
        let newsboardComponent = {
            type: type,
            title: title,
            subtitle: subtitle,
            date: new Date,
            removeDate: removeDate ? removeDate : moment().add(4, "weeks"),
            seen: false,
            hidden: false,
            customData: customData
        }

        this.newsboard.push(newsboardComponent)
    },
    formatDate(newsDate) {
      var date = new Date(newsDate)

      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];

      const day = date.getDate();
      const month = months[date.getMonth()];
      const hours = ('0' + date.getHours()).slice(-2);
      const minutes = ('0' + date.getMinutes()).slice(-2);

      // Function to add ordinal suffix to day
      const addOrdinalSuffix = (day) => {
        if (day >= 11 && day <= 13) {
          return `${day}th`;
        }
        switch (day % 10) {
          case 1: return `${day}st`;
          case 2: return `${day}nd`;
          case 3: return `${day}rd`;
          default: return `${day}th`;
        }
      };

      const formattedDay = addOrdinalSuffix(day);

      return `${month} ${formattedDay} ${hours}.${minutes}`;
    },
    createNewUserNews() {
      //var type = "NewsboardDialogue";
      var type = "NewsboardNewuser";
      var title = this.translations.newsboardNewUser
      this.createNewsboardComponent(type, title);
    },
    getUserTimeZone() {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return userTimeZone;
    },
    
    async checkIfAnyMessageBoardEvents() {
      var oldCheckDate = app.api.store.get("messageBoardLocationsLastChecked")
      var newCheckDate = new Date();
      this.checkIfMessageBoardEvents(oldCheckDate)

      app.api.store.set("messageBoardLocationsLastChecked", newCheckDate.getTime())
    },
    async checkIfMessageBoardEvents(oldCheckDate) {
      var messageBoardNotifications = {}

      messageBoardNotifications = await MicroServiceAPI.GetMessageBoardPostNotifications({MessageBoardId: "00000000-0000-0000-0000-000000000000"}, oldCheckDate)

      if(messageBoardNotifications.messageBoardPostsRepliedToCount > 0) {
        var type = "NewsboardDialogue";
        var title = this.translations.newsboardReplied;

        messageBoardNotifications.messageBoardPostsRepliedTo.forEach(post => {
          post.replies.forEach(reply => {
            var messageBoardData = app.api.PageManager.getContentComponentFromID(this.availableMessageBoards.find(board => board.messageBoardID === post.messageBoardId).contentID);
            this.createNewsboardComponent(type, title.replace("XX", reply.user).replace("YY", messageBoardData.data.title), "", null, {messageBoardData: messageBoardData});
          })
        })
      }

      if(messageBoardNotifications.messageBoardPostsReactedToCount > 0) {
        var type = "NewsboardDialogue";
        var title = this.translations.newsboardLiked;

        messageBoardNotifications.messageBoardPostsReactedTo.forEach(post => {
          post.reactions.forEach(reaction => {
            var messageBoardData = app.api.PageManager.getContentComponentFromID(this.availableMessageBoards.find(board => board.messageBoardID === post.messageBoardId).contentID);
            this.createNewsboardComponent(type, title.replace("XX", reaction.user).replace("YY", messageBoardData.data.title), "", null, {messageBoardData: messageBoardData});
          })
        })
      }
    },

    checkCustomMessages() {
      var now = moment().tz(this.userTimeZone);

      this.customMessages.forEach(message => {
        this.checkIfMessageIsNow(message, message.startTime, now, message.endTime)
      })
    },
    checkIfMessageIsNow(event, startTime, now, endTime) {

      if(startTime.isSameOrBefore(now) && now.isSameOrBefore(endTime)) {
        var type = "NewsboardCustomMessage";
        var title = event.title
        var subtitle = event.subtitle
        var removeDate = endTime
        var customData = {
          contentID: event.link.contentID,
          sceneID: event.link.sceneID,
          icon: event.icon ? event.icon.src : null
        }
        
        if(!this.checkIfNewsExist(type, title, subtitle))
          this.createNewsboardComponent(type, title, subtitle, removeDate, customData);
      }
    },
    getCDNpath(asset) {
      return app.api.Utils.getCDNpath(asset)
    }
  },
  computed: {
    shownNewsboard() {
      return this.newsboard.filter(news => news.hidden === false)
    },
    unseenCounter() {
      let count = 0;

      this.newsboard.forEach(news => {
        if (news.seen === false) {
          count += 1
        }
      })

      return count
    },
    translations() {
      return app.api.PageManager.getCustomComponentFromType("uIText").data
    },
    availableMessageBoards() {
      let availableMessageBoards = []

      this.translations.newsboardChatsReferences.forEach(newsboard => {
        availableMessageBoards.push({messageBoardID: app.api.PageManager.getContentComponentFromID(newsboard.chatID).data.messageBoardID, contentID: newsboard.chatID})
      })

      return availableMessageBoards
    },
    customMessages() {
      var arr = [];

      arr = this.translations.newsboardCustomMessages.map(message => {
        message.startTime = moment(message.time).tz(this.userTimeZone);
        message.endTime = moment(message.latestTime).tz(this.userTimeZone);
        
        return message;
      }) 

      return arr
    }
  },
};
</script>
<style lang="scss">
$NewsboardButtonWidth: 138px;
$NewsboardButtonHeight: 138px;

#Newsboard {
  position: absolute;
  left: 0;
  bottom: 0;

  transition-property: transform;
  transition-duration: 0.25s;
  transition-timing-function: var(--tk-easeinout);
}

.Newsboard__button {
  position: absolute;
  transition-property: transform;
  transition-duration: 0.25s;
  transition-timing-function: var(--tk-easeinout);
  left: 42px;
  bottom: 37px;
  z-index: 2;

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border-radius: 100%;
    left: 0;
    top: 0;
    background: var(--orange);
    width: 26px;
    height: 26px;
    font-size: 16px;
    font-weight: bold;
  }

  &_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $NewsboardButtonWidth;
    height: $NewsboardButtonHeight;
    background: #fff;
    border-radius: 100%;
    border: solid 6px #fff;
    overflow: hidden;

    & > video {
      clip-path: inset(1px 1px);
    }

  }

  &::before {
    content: '';
    background-image: url(@/assets/img/ui/newsboard-active.png);
    background-repeat: no-repeat;
    background-position: center;
    width: 231px;
    height: 206px;
    position: absolute;
    left: -8px;
    bottom: -8px;
    opacity: 0;
    pointer-events: none;
    z-index: -1;
  }
  
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.87);
    width: calc(100% + 17px);
    height: calc(100% + 17px);
    border-radius: 100%;
    background: var(--light-blue);
    transition-property: transform;
    transition-timing-function: var(--tk-easeinout);
    transition-duration: 0.2s;
    z-index: -1;
  }

  span {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background: rgba(255, 255, 255, 0.8);
    width: 44px;
    height: 44px;
    top: -34px;
    right: -34px;
    border-radius: 100%;
    pointer-events: none !important;
    
    &::after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      top: calc(100% + 6px);
      right: -2px;
      background: rgba(255, 255, 255, 0.6);
      border-radius: 100%;
      pointer-events: none; 
    }
  }

  &--hasnews {
    span {
      pointer-events: all!important;
      background: var(--red);
    }
  }


  &:hover {
    &::after {
      transform: translate(-50%, -50%) scale(1);
    }
  }
}

.Newsboard__window {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 335px;
  height: 511px;
  left: 215px;
  bottom: 162px;
  transition-property: transform, opacity;
  transition-duration: 0.25s;
  transition-timing-function: var(--tk-easeinout);
  color: #fff;
  z-index: 2;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-color: var(--light-blue);
    z-index: -1;
    border-radius: 26px 26px 26px 75px;
  }

  h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px 16px 0px 18px;
    padding-bottom: 14px;
    font-size: 22px;
    color: var(--dark-blue);
    font-weight: bold;
    flex-grow: 0;
    border-bottom: 1px solid #fff;
  }

  &>div {
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
    border-radius: 0 0 0 76px;
  }

  ul {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;

    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      text-align: left;
      margin: 18px 16px 0 18px;
      padding-bottom: 10px;
      color: var(--dark-blue);
      border-bottom: 1px solid #fff;
      font-size: 16px;

      &:first-of-type {
        border-bottom: none;
      }

      &>div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;

        &>img {
          width: 26px;
        }
        
        &>div {
          position: relative;
          flex-grow: 1;
          display: flex;
          justify-content: flex-end;
          align-items: center;


          span {
            font-size: 12px;
            color: var(--main-color);
            transition: transform var(--tk-easeinout) 0.22s;
          }

          &>button {
            position: absolute;
            right: 0;
            bottom: -4px;
            opacity: 0;
            transform: translateX(10px);
            transition-property: transform, opacity;
            transition-duration: 0.22s;
            transition-timing-function: var(--tk-easeinout);

            &> img { 
              width: 16px;
              transition: 0.35s transform var(--tk-easeinout);
            }

            &::after {
              content: '';
              position: absolute;
              width: 200%;
              height: 200%;
              left: 50%;
              top: 50%;
              transform: translateX(-50%) translateY(-50%);
            }

            &:hover {
              &>img {
                transform: rotate(90deg);
              }
            }
          }
        }

      }

      h3 {
        font-weight: bold;
      }

      &> button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 18px 10px 22px;
        background-color: var(--main-color);
        margin-bottom: 12px;
        color: #fff;
        border-radius: 1000px;

        transition-property: background-color;
        transition-duration: 0.15s;
        transition-timing-function: ease;

        span {
          display: flex;
          align-items: center;
          position: relative;

          &::after {
            content: "";
            margin-left: 10px;
            width: 19px;
            height: 15px;
            background-image: url(@/assets/img/ui/arrow-right_white.png);
            background-position: no-position;
            background-repeat: no-repeat;
          }
        }

        &:hover {
          background-color: var(--dark-blue);
        }
      }

      &:hover {
        &>div {
          &>div {
            &> button {
              opacity: 1;
              transform: translateX(-5px);
              transition-duration: 0.4s;
            }

            span {
              transform: translateX(-30px);
            }
          }
        }
      }
    }
  }

  &_close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 44px;
    height: 44px;
    background: var(--main-color);
    border-radius: 100%;
    bottom: -60px;

    &> img {
      width: 16px;
      height: 16px;
    }

    &::before {
      content: "";
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      box-shadow: var(--shadow-medium);
      transition-property: opacity;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }

    &:hover {
      transform: scale(1.02);

      &::before {
          opacity: 0.8;
      }
    }
  }
}

.Newsboard--open {
  z-index: 3;

  .Newsboard__button {
    &::before {
      opacity: 1;
    }

    span {
      opacity: 0;
    }

    &::after {
      transform: translate(-50%, -50%) scale(0.87);
    }
  }

  .Newsboard__window {
    opacity: 1;
  }
}
</style>