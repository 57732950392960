<template>
    <button id="CalendarButton" @click="openCalendar">
        <span>{{buttonText}}</span>
        
    </button>
</template>
<script>
export default {
    methods: {
        openCalendar() {
            app.api.PageManager.openContentComponentFromID(this.calendarID)
        }
    },
    computed: {
        calendarID() {
            return app.api.PageManager.getCustomComponentFromType("uIText").data.programmeContentID;
        },
        buttonText() {
            return app.api.PageManager.getCustomComponentFromType("uIText").data.calendarButtonText
        }
    }
}
</script>
<style lang="scss">

#CalendarButton {
    position:absolute;
    right: 45px;
    bottom: 40px;
    background: var(--main-color);
    color: #fff;
    padding: 0 24px 0 24px;
    justify-content: center;
    height: 53px;
    border-radius: 1000px;
    z-index: 2;
    transition-property: background color;
    transition-duration: 0.2s;
    transition-timing-function: ease;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        box-shadow: var(--shadow-medium);
        opacity: 0;
        transition-property: opacity;
        transition-duration: 0.2s;
        transition-timing-function: ease;
    }

    &:hover {
        background: #fff;
        color: var(--main-color);

        &::before {
            opacity: 1;
        }
    }
}
</style>