<template>
  <li>
    <div>
      <img src="@/assets/img/ui/attend-livestream.png">
      <div>
        <span>{{formattedDate}}</span>
        <button @click="removeNews"><img src="@/assets/img/ui/close_blue.png"></button>
      </div>
    </div>
    <h3>{{news.title}}</h3>
    <p>{{news.subtitle}}</p>
    <button @click="gotoLivestream">
      <span>{{translations.newsboardView}}</span>
    </button> 
  </li>
</template>

<script>


export default {
  props: [
    "news",
    "formattedDate",
    "translations",
    "customData"
  ],
  data() {
    return {
      
    }
  },
  mounted() {
  },
  methods: {
    gotoLivestream() {
      var route = app.api.PageManager.getRouteFromID(this.livestreamScene);
      var contentslug = app.api.PageManager.getContentComponentFromID(this.livestreamContent).slug 
      app.api.PageManager.navigateToRoute(route + ":" + contentslug);
    },
    removeNews() {
      this.$emit("removeNews", this.news)
    }
  },
  unmounted() {
  },
  computed: {
    livestreamScene() {
      return this.translations.livestreamLink.sceneID
    },
    livestreamContent() {
      return this.translations.livestreamLink.contentID
    }
  }
};
</script>

<style lang="scss" scoped>

</style>