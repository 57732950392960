<template>
  <li>
    <div>
      <img v-if="customData.icon === null" src="@/assets/img/ui/star.png">
      <img v-else :src="getCDNpath(customData.icon)">
      <div>
        <span>{{formattedDate}}</span>
        <button @click="removeNews"><img src="@/assets/img/ui/close_blue.png"></button>
      </div>
    </div>
    <h3>{{news.title}}</h3>
    <p>{{news.subtitle}}</p>
    <button v-if="hasLink" @click="goToLink">
      <span>{{translations.newsboardView}}</span>
    </button>
  </li>
</template>

<script>


export default {
    props: [
        "news",
        "formattedDate",
        "translations",
        "customData"
    ],
    methods: {
        removeNews() {
            this.$emit("removeNews", this.news)
        },
        goToLink() {
            app.api.PageManager.navigateToRoute(app.api.PageManager.getRouteFromIDs(this.customData.sceneID, this.customData.contentID));
        },
        getCDNpath(asset) {
            return app.api.Utils.getMediaPath(asset)
        },
    },
    computed: {
        hasLink() {
            return this.customData.contentID !== null || this.customData.sceneID !== null
        }
    },
};
</script>