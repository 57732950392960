 <template>
  <transition name="toggleContentComponent">
    <article class="expertAreasConnect" v-if="ready">
        <div class="expertAreasConnect__center">
            <ExpertAreasBackground></ExpertAreasBackground>
            <!-- BEFORE SHARING -->
            <div class="expertAreasConnect__center__left" v-if="hasShared === false">
                <h3 v-html="properties.title"></h3>
                <div v-html="properties.text"></div>
                <div class="expertAreasConnect__center__left_dropdown dropdownHotspot" :class="{'dropdownHotspot--open': regionDropdownOpen, 'dropdownHotspot--active': selectedRegion !== null}">
                    <button @click="regionToggleDropdown">
                        <span v-html="selectRegionButtonText"></span>
                    </button>
                    <ul v-if="regionDropdownOpen">
                        <li v-for="region in properties.selectRegionDropdown" :key="region" @click="selectRegion(region)">{{region}}</li>
                    </ul>
                </div>

                <div class="expertAreasConnect__center__left_dropdown dropdownHotspot" :class="{'dropdownHotspot--open': functionDropdownOpen, 'dropdownHotspot--active': selectedFunction !== null}">
                    <button @click="functionToggleDropdown">
                        <span v-html="selectFunctionButtonText"></span>
                    </button>
                    <ul v-if="functionDropdownOpen">
                        <li v-for="func in properties.selectFunctionDropdown" :key="func" @click="selectFunction(func.title)">{{func.title}}</li>
                    </ul>
                </div>

                <button class="expertAreasConnect__center__left_sharebutton" :class="{'expertAreasConnect__center__left_sharebutton--inactive': selectedRegion === null || selectedFunction === null}" @click="shareFootprint" v-html="properties.shareButton"></button>
                <div class="expertAreasConnect__center__left_guidetext" v-html="properties.guideText"></div>
            </div>

            <div class="expertAreasConnect__center__left" v-else>
                <h3 v-html="properties.confirmationTitle"></h3>
                <div v-html="properties.confirmationText"></div>
                <button class="expertAreasConnect__center__left_sharebutton" @click="joinChat" v-html="properties.confirmationButton"></button>
            </div>


            <div class="expertAreasConnect__center__right">
                <ExpertAreasCircle :selectedRegion="selectedRegion" :selectedFunction="selectedFunction" :leftCircle="properties.selectRegionDropdown" :rightCircle="properties.selectFunctionDropdown" :hasShared="hasShared" :lines="lines" @pointClicked="pointClicked"></ExpertAreasCircle>

                <div class="expertAreasConnect__center__right_explainer">
                  <img src="@/assets/img/ui/sharing-explainer.png">
                  <p v-html="properties.explainerText"></p>
                </div>

                <div class="expertAreasConnect__center__right_shared">
                    <div>
                        <span>{{hasSharedCount}}</span>
                        <p>{{properties.confirmationHasShared}}</p>
                    </div>
                    <img src="@/assets/img/ui/shared-icon.png">
                </div>
            </div>
        </div>
    </article>
  </transition>
</template>

<script>
export default {
  props: ["properties", "pageData", "slug", "componentID"],
  data() {
    return {
      ready: false,
      loadingHasShared: false,
      hasShared: false,
      hasSharedCount: 0,

      regionDropdownOpen: false,
      selectedRegion: null,

      functionDropdownOpen: false,
      selectedFunction: null,

      lines: []
    };
  },
  async mounted() {
    this.checkHasShared();
    await this.countShared();
    this.ready = true;
  },
  methods: {
    async checkHasShared() {
      let userData = await MicroServiceAPI.GetAsciiDumpsForAreaCurrentUser({
        MaxResults: 100000,
        MaxAgeSeconds: 0,
        DumpAreaId: this.properties.aSCIIAreaID
      })
      
      if(userData.length > 0) {
        let processedData = JSON.parse(userData)
        let processedUserData = JSON.parse(processedData[0].dumpValue)

        this.selectedRegion = processedUserData.region
        this.selectedFunction = processedUserData.function
        this.hasShared = true;
      }

      this.loadingHasShared = true;
      
    },
    async countShared() {
      let dumps = await MicroServiceAPI.GetAsciiDumpsForArea({
        MaxResults: 100000,
        MaxAgeSeconds: 0,
        DumpAreaId: this.properties.aSCIIAreaID
      });
      
      
      let processedData = JSON.parse(dumps);
      processedData.map(entry => {
        entry.dumpValue = JSON.parse(entry.dumpValue)
        return entry;
      })

      this.properties.selectFunctionDropdown.map(funct => {
        var count = 0;

        processedData.forEach(entry => {
          if(funct.title === entry.dumpValue.function)
            count++;
        })

        funct.footprintText = this.properties.haveSharedText.replace("XX", count)
        return funct
      })

      this.hasSharedCount = processedData.length;

      this.createLinePoints(processedData);
    },
    createLinePoints(data) {
      let uniqueLines = new Set();

      data.forEach(item => {
        uniqueLines.add(JSON.stringify(item.dumpValue))
      })

      this.lines = Array.from(uniqueLines).map(item => {
        return JSON.parse(item);
      });
    },
    async shareFootprint() {
      if (this.selectedRegion === null || this.selectedFunction === null)
        return;

      let shareData = JSON.stringify({
        region: this.selectedRegion,
        function: this.selectedFunction       
      })

      await MicroServiceAPI.PostAsciiDump({
        AsciiDumpId: "00000000-0000-0000-0000-000000000000",
        DumpAreaId: this.properties.aSCIIAreaID,
        DumpKey: "00000000-0000-0000-0000-000000000000",
        DumpValue: shareData,
        IsUniqueForUser: true
      })

      this.hasShared = true;
    },

    joinChat() {
      let chatID = this.properties.selectFunctionDropdown.find(f => f.title === this.selectedFunction).chatID;
      app.api.PageManager.openContentComponentFromID(chatID, true);
    },

    regionToggleDropdown() {
      this.regionDropdownOpen = !this.regionDropdownOpen;
      this.functionDropdownOpen = false;
    },
    selectRegion(region) {
      this.selectedRegion = region;
      this.regionDropdownOpen = false;
    },

    functionToggleDropdown() {
      this.functionDropdownOpen = !this.functionDropdownOpen;
      this.regionDropdownOpen = false;
    },
    selectFunction(func) {
      this.selectedFunction = func;
      this.functionDropdownOpen = false;
    },

    pointClicked(point) {
      this.functionDropdownOpen = false;
      this.regionDropdownOpen = false;

      switch (point.type) {
        case "region":
          this.selectedRegion = point.point;
          break;
        case "function":
          this.selectedFunction = point.point;
          break;
      }
    },
    closeComponent() {
      this.$emit("closeComponent", this.slug);
    },
  },
  computed: {
    selectRegionButtonText() {
      return this.selectedRegion === null ? this.properties.selectRegionButton : this.selectedRegion;
    },
    selectFunctionButtonText() {
      return this.selectedFunction === null ? this.properties.selectFunctionButton : this.selectedFunction;
    },
  },
};
</script>

<style lang="scss">
.expertAreasConnect {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: #fff;

  &__center {
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 1920px;
    font-size: 18px;

    &__left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 500px;
      min-width: 500px;
      padding: 0 65px 0 105px;
      color: #fff;
      text-align: left;
      opacity: 0;
      
      animation-name: expertAreasConnectTextAnimation;
      animation-delay: 0.1s;
      animation-duration: 0.4s;
      animation-timing-function: var(--tk-easeinout);
      animation-fill-mode: forwards;

      h3 {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 16px;
      }

      &_dropdown {
        position: relative;
        margin-bottom: 18px;

        ul {
          position: absolute;
          width: 100%;
          li {
            margin-bottom: 0;
            cursor: pointer;
          }
        }
      }

      &_sharebutton {
        text-align: center;
        background: var(--main-color);
        width: 100%;
        height: 53px;
        padding: 0 24px 0 24px;
        border-radius: 1000px;
        opacity: 1;
        transition-property: background, color, opacity;
        transition-duration: 0.2s;
        transition-timing-function: var(--tk-easeinout);

        &:hover {
          background: #fff;
          color: var(--main-color);
        }

        &--inactive {
          pointer-events: none;
          opacity: 0.3;
        }
      }
      &_guidetext {
        margin: 1.5em 0 0;
        a {
          color: inherit;
          text-decoration: underline;
        }
      }
    }

    &__right {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 66%;

      &_explainer {
        color: var(--dark-blue);
        position: absolute;
        top: 120px;
        left: -10px;
        display: flex;
        align-items: flex-start;
        gap: 22px;

        img {
          width: 80px;
          height: auto;
        }

        p {
          font-size: 18px;
          max-width: 250px;
          text-align: left;
          b { font-weight: 700;}
        }
      }

      &_shared {
        position: absolute;
        display: flex;
        right: 34px;
        bottom: 60px;
        text-align: right;

        & > div {
          color: var(--dark-blue);
          margin-right: 10px;
          span {
            font-size: 50px;
          }
          p {
            font-size: 30px;
            font-weight: bold;
            margin: -16px 0 0 0;
          }
        }

        img {
          align-self: center;
        }
      }
    }

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 50vw;
      top: 0;
      z-index: -1;
      background: var(--secondary-color);
      left: 420px;
      transform: translateX(-100%);
      pointer-events: none;
    }
  }
}

@keyframes expertAreasConnectTextAnimation {
  0% {
    opacity: 0;
      transform: translateX(-40px);
  }

  100% {
    opacity: 1;
      transform: translateX(0);
  }
}
</style>