<template>
  <div class="expertAreasConnect__circle" :style="[getPosition, getScale]">
    <svg :viewBox="svgViewport">
      <!-- Draw lines grouped -->
      <g ref="linesGroup"></g>
       
      <!-- Draw points for the left half circle -->
      <circle v-for="point in leftPoints" :key="point.title"
        :cx="point.x" :cy="point.y" r="1" fill="#fff" stroke="#001965" stroke-width="0.3"/>
      <!-- Draw points for the right half circle -->
      <circle v-for="(point, index) in rightPoints" :key="'right-' + index"
        :cx="point.x" :cy="point.y" r="1" fill="#fff" stroke="#001965" stroke-width="0.3"/>
    </svg>
    <img src="@/assets/img/ui/expertareasconnect-bg.png">
  </div>
</template>

<script>
export default {
    props: [
        "properties",
        "pageData"
    ], 
    data() {
        return {
            svgViewportValue: 100,
            radius: 33.4,
            leftPoints: [],
            rightPoints: [],
            lineDelay: 2000,
            removeTime: 15000,
            animationDuration: 500,
            linesToCreate: 10,
            randomLinesTimer: null
        };
    },
    created() {
        this.createPoints()
    },
    mounted() {
        this.cycleRandomLines()
    },
    methods: {
        createPoints() {
            this.calculatePointsLeft();
            this.calculatePointsRight();
        },
        calculatePointsLeft() {
            const startPosition = -1.35; // Define the position the circle section starts (adjust as needed)
            const maxCurve = 0.705; // Define the maximum angle of the curve (adjust as needed)

            const calculatedMaxCurve = Math.PI * maxCurve; 
            const angleStep = calculatedMaxCurve / (this.properties.pointsLeftCount - 1); 
            

            for(var i = 0; i < this.properties.pointsLeftCount; i++) {
                this.leftPoints.push(this.calculatePoint(startPosition, i, angleStep))
            }
        },
        calculatePointsRight() {
            const startPosition = 1.59;// Define the position the circle section starts (adjust as needed)
            const maxCurve = 0.823; // Define the maximum angle of the curve (adjust as needed)

            const calculatedMaxCurve = Math.PI * maxCurve;
            const angleStep = calculatedMaxCurve / (this.properties.pointsRightCount - 1); 

            for(var i = 0; i < this.properties.pointsRightCount; i++) {
                this.rightPoints.push(this.calculatePoint(startPosition, i, angleStep))
            }
        },
        calculatePoint(startPosition, i, angleStep) {
            const angle = startPosition * Math.PI + i * angleStep;
            const x = (this.svgViewportValue / 2) + this.radius * Math.cos(angle);
            const y = (this.svgViewportValue / 2) + this.radius * Math.sin(angle);

            return {x, y}
        },
        createRandomLines(lines) {
            let delay = 0; // Initial delay
            var i = 0

            while (i < lines) {
                // Generate random indices for the left and right circles
                const leftIndex = Math.floor(Math.random() * this.leftPoints.length);
                const rightIndex = Math.floor(Math.random() * this.rightPoints.length);
                // Get the points corresponding to the random indices
                const leftPoint = this.leftPoints[leftIndex];
                const rightPoint = this.rightPoints[rightIndex];

                // Draw SVG line between the points with delay
                this.drawSVGLine(leftPoint, rightPoint, delay);

                // Increase the delay for the next line
                delay += this.lineDelay;
                
                i++;
            }
        },
        drawSVGLine(leftPoint, rightPoint, delay) {
            setTimeout(() => {
                // Create SVG line element
                const line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
                line.setAttribute('x1', leftPoint.x);
                line.setAttribute('y1', leftPoint.y);
                line.setAttribute('x2', rightPoint.x);
                line.setAttribute('y2', rightPoint.y);
                line.classList.add('svgLine')
                line.setAttribute('stroke', '#005AD2');
                line.setAttribute('stroke-width', '0.2');

                // Append the line to the SVG element using the ref
                if(!this.$refs.linesGroup)
                    return

                this.$refs.linesGroup.appendChild(line);


                setTimeout(() => {
                    line.style.opacity = '0';
                }, this.removeTime - this.animationDuration);
                
                setTimeout(() => {
                    if(!this.$refs.linesGroup)
                        return
                    this.$refs.linesGroup.removeChild(line);
                }, this.removeTime);
            }, delay);
        },
        cycleRandomLines() {
            this.createRandomLines(this.linesToCreate);


            this.randomLinesTimer = setTimeout(() => {
                this.cycleRandomLines();
            }, (this.linesToCreate * this.lineDelay));
        }
    },
    beforeUnmount() {
        clearTimeout(this.randomLinesTimer)
    }, 
    computed :{
        svgViewport() {
            return `0 0 ${this.svgViewportValue} ${this.svgViewportValue}`
        },
        getPosition() {
            return {
                left: this.properties.positionData.xPosition + 'px',
                top: this.properties.positionData.yPosition + 'px'
            }
        },
        getScale() {
            return {
                transform: `scale(${this.properties.scale})`
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.expertAreasConnect__circle {
    width: 1000px;
    height: 1000px;

    svg {
        circle {
            cursor: default;
            &:hover {
                fill: #fff;
            }
        }
    }
}
    
</style>