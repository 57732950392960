<template>
    <a @click="goBack()" id="back-button" class="btnfx btnfx__clickflash btnfx__hoverscale">
        <img src="@/assets/img/ui/arrow_back.png">
    </a>
</template>
<script>

export default {
    methods: {
        goBack() {
            app.api.PageManager.navigateToParentScene();
        }
    }
}
</script>
<style lang="scss" scoped>
#back-button {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--main-color);
    top: 40px;
    // left: 100px;
    left: 100px;
    z-index: 2;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &>img {
        margin-right: 5px;
    }
}
</style>